import React from 'react';
import { useLocation } from 'react-router-dom';

function Footer(props: any): any {
  const clientVersion = process.env.REACT_APP_VERSION ?? '0.0.0';
  const DisplayableClientVersion = String('v' + clientVersion);
  const location = useLocation();
  return (
    <div
      className={
        location.pathname === '/register'
          ? 'relative bottom-0 bg-black md:grid  xl:grid xl:grid-cols-8 gap-4 py-4 xl:py-4 z-[9999] mt-[150px]'
          : 'relative bottom-0 bg-black md:grid  xl:grid xl:grid-cols-8 gap-4 py-4 xl:py-4 z-[9999]'
      }
    >
      <div className="relative h-16 w-132">
        <div className="absolute text-white bottom-0 left-0 h-32 w-32 pt-28 pl-2 ...">
          © BT Group plc
        </div>
      </div>
      <div className="relative h-16 w-132">
        <div className="absolute text-white bottom-0 left-0 h-32 w-32 pt-28 pl-2 ...">
          {DisplayableClientVersion}
        </div>
      </div>
    </div>
  );
}

export default Footer;
