import React, { useState, useEffect, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import Button from '../library/Button/button';
import { UserType } from '../utils/Helper/enums';
import UserLogin from '../utils/UserLogin/UserLogin';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import { LoginContext } from '../context/IppContext';
import { encrypt } from '../utils/Helper/encryption';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import Label from '../library/label/label';
import InputField from '../library/inputField/inputField';
import backgroundImage from './login-1200-800.jpeg';

const Login: React.FC<any> = (props: any) => {
  interface FormValues {
    email: string;
    password: string;
    token: string;
  }
  const [passwordShown, setPasswordShown] = useState(false);
  const [isPopupEnabled, setIsPopupEnabled] = useState(false);
  const [isResendUrl, setIsResendUrl] = useState(false);
  const [isResendSuccessful, setResendSuccessful] = useState(false);
  const [isCredentialInvalid, setCredentialInvalid] = useState(false);
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [email, setEmail] = useState('');
  const [userPass, setUserPass] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const { loginToken, updateToken } = useContext(LoginContext);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState(false);
  const [isTempLocked, setIsTempLocked] = useState(false);
  let isLoggedIn: boolean = false;
  const navigate: NavigateFunction = useNavigate();
  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';

  useEffect(() => {
    if (isLoggedIn && loginToken) {
      navigate('/admin');
    }
  }, [loginToken]);

  // login method for users who want to sign-in to Organization user page
  const doLogin: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault();
    if (navigator.onLine) {
      setShowLoader(true);
      setTimeout(() => setShowLoader(false), 9000);
      const userLoginObj = new UserLogin({
        email: data.email || '',
        password: data.password || ''
      });
      setEmail(data.email ?? '');
      setUserPass(data.password ?? '');

      sessionStorage.setItem('userPassword', encrypt(data.password));
      setResendSuccessful(false);
      setCredentialInvalid(false);
      setIsResendUrl(false);
      const loginStatus = await userLoginObj.doLogin('');
      const responseData: any = loginStatus?.body ?? {};
      const userType = responseData?.userType ?? Number.MAX_SAFE_INTEGER;
      switch (loginStatus.statusCode) {
        case 201:
          setIsPopupEnabled(false);
          switch (userType) {
            case UserType.BtAdmin:
              navigate('/btadmin');
              break;
            case UserType.OrganizationAdmin:
              isLoggedIn = true;
              updateToken(JSON.stringify(responseData));
              sessionStorage.setItem('secretCode', responseData.secretCode);
              sessionStorage.setItem(
                'accessToken',
                responseData.cognitoDetails.message.accessToken.jwtToken
              );
              sessionStorage.setItem('isMfaSetup', responseData.isMfaSetup);
              navigate('/termsandconditions');
              break;
            case UserType.OrganizationUser:
              updateToken(JSON.stringify(responseData));
              sessionStorage.setItem('secretCode', responseData.secretCode);
              sessionStorage.setItem(
                'accessToken',
                responseData.cognitoDetails.message.accessToken.jwtToken
              );
              sessionStorage.setItem('isMfaSetup', responseData.isMfaSetup);
              navigate('/termsandconditions');
              break;
            default:
              updateToken(JSON.stringify(responseData));
              break;
          }
          break;
        case 301:
          sessionStorage.setItem('isMfaSetup', 'true');
          navigate('/termsandconditions');
          break;
        case 423:
          setIsPopupEnabled(true);
          setIsLocked(true);
          break;
        case 429:
          setIsPopupEnabled(true);
          setIsTempLocked(true);
          break;
        case 403:
          setIsNotVerified(true);
          setIsPopupEnabled(true);
          break;
        case 401:
          setIsResendUrl(true);
          setCredentialInvalid(true);
          setIsPopupEnabled(true);
          break;
        case 500:
          alert('User Email is invalid');
          navigate(0);
          break;
        default:
          setIsResendUrl(false);
          setCredentialInvalid(true);
          setIsPopupEnabled(true);
      }
      // Storing the login token and email address of the user
      sessionStorage.setItem('userEmail', data.email);
      sessionStorage.setItem('user-type', userType);
    } else {
      alert(
        'Sorry, there is not internet connection, available, please connect your device with a network.'
      );
    }
  };

  const popupCloseHandler = (e: boolean) => {
    sessionStorage.clear();
    updateToken('');
    navigate(0);
    setVisibility(e);
  };

  const qrCodePopupCloseHandler = (e: boolean) => {
    setVisibility(false);
  };

  // Resend mail for user who wants to sign-in to Organization User page
  const resendMail = async () => {
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 5000);
    const userLoginObj = new UserLogin({ email: email || '', password: userPass || '' });
    setCredentialInvalid(false);
    const res = await userLoginObj.resendUrl();
    switch (res.statusCode) {
      case 201:
        setResendSuccessful(true);
        setEmail('');
        setUserPass('');
        break;
      default:
        setResendSuccessful(false);
        setEmail('');
        setUserPass('');
    }
  };

  // Function to check if Sign-in as btadmin is checked.

  return (
    <div>
      <div className="m-0 p-0 align-baseline">
        <div className="z-10 pt-3.5 min-h-[900px] clear-both w-full relative overflow-hidden ">
          {/* Header start */}
          <Header>
            <WelcomeHeader />
          </Header>
          {/* Header End */}
          {/* Border Start */}
          <div
            className="w-full absolute z-50 mt-0 clear-both h-px"
            style={{
              borderTop: '1px solid #ccc'
            }}
          ></div>
          <div className="clear-both"></div>
          {/* Border End */}
          {/* Login Panel start */}
          <div
            className="sm:w-[980px] sm:pt[5rem] mx-auto mt-0 mb-0 px-4 min-h-[380px] min-w-[288px] table z-[5] relative flex justify-center"
            style={{
              backgroundPositionX: '0px',
              backgroundPositionY: '0px'
            }}
          >
            <div className="block sm:mt-[8rem] mt-[11rem] sm:p-[0.5rem] rounded-lg shadow-lg sm:my-8 sm:mx-[19.625rem] pointer-events-auto sm:float-none sm:justify-center bg-white">
              <form
                className="my-form"
                method="post"
                action=""
                name="frm_login"
                onSubmit={wrapAsyncFunction(handleSubmit(doLogin))}
              >
                <div className="p-4 pb-[2rem]">
                  {/* loginsection start */}
                  <div className="w-full">
                    <div className="text-[22px] text-center mb-[15px]">Log in</div>
                    <Label htmlFor="validationCustomEmail" className="inline mb-0 mt-2">
                      {errors.email == null ? (
                        'Email'
                      ) : (
                        <span>Please enter your email address</span>
                      )}
                    </Label>

                    <InputField
                      id={'LoginEmail'}
                      className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
                      type="email"
                      placeholder="This is usually your email address"
                      register={register('email', {
                        required: true
                      })}
                      style={
                        errors.email == null
                          ? { border: '1px solid #666' }
                          : { border: '1px solid #e60014' }
                      }
                    />
                  </div>
                  {/* loginsection End */}
                  <Label className="clear-left text-[#333] mt-1.5 border border-border-grey pb-2.5 hidden"></Label>
                  <p className="mb-4 clear-both text-xs pb-0 mr-0 ml-0 mt-0"></p>
                  <Label className="clear-left text-base inline mb-0 text-[#333] m-0 p-0 align-baseline">
                    {errors.password == null ? 'Password' : <span>Please enter your password</span>}
                  </Label>
                  <Link
                    className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
                    onClick={(e) => togglePassword(e)}
                    to={''}
                  >
                    {buttonText}
                  </Link>
                  <InputField
                    id={'LoginPassword'}
                    className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')]"
                    type={passwordShown ? 'text' : 'password'}
                    register={register('password', { required: true })}
                    style={
                      errors.password == null
                        ? { border: '1px solid #666' }
                        : { border: '1px solid #e60014' }
                    }
                  />
                  <p className="mt-2 ml-16 clear-both text-sm pb-0 m-0 text-[#666]">
                    {/* Redirect to send verfication code page */}
                    <Link className="text-sm text-black underline" to={'/reset/otp'}>
                      Forgot your password
                    </Link>
                    &nbsp;
                  </p>
                  &nbsp; &nbsp;
                  <Button
                    className="p-0 tracking-normal mb-2 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]"
                    buttonText="Log in"
                    onclick={(e) => {
                      setVisibility(!visibility);
                    }}
                    loading={showLoader}
                    disabled={showLoader}
                  />
                  {isPopupEnabled && (
                    <CustomPopup onClose={popupCloseHandler} show={visibility}>
                      <div className="block">
                        {/* <h3 className="pr-[25%] -mt-[14px] mb-2.5 text-[17px] leading-[1.5em] text-[#333]">
                          {!isResendSuccessful && !isCredentialInvalid
                            ? 'User is not confirmed'
                            : ''}
                        </h3> */}
                        <p className="mt-0 mr-0 mb-2.5 ml-0 text-[16px]">
                          {isCredentialInvalid
                            ? 'Email or Password is invalid'
                            : isLocked
                            ? 'Acount is Locked permanantly, please connect to BT Admin'
                            : isTempLocked
                            ? 'Account is temporarily Locked, Please try after 10 mins'
                            : isNotVerified
                            ? 'User is not verified'
                            : 'Please wait'}
                        </p>
                        {/* {isResendUrl && !isResendSuccessful && (
                          <Button
                            className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
                            buttonText="Resend"
                            onclick={wrapAsyncFunction(resendMail)}
                            loading={showLoader}
                            disabled={showLoader}
                          />
                        )} */}
                      </div>
                    </CustomPopup>
                  )}
                </div>
              </form>
            </div>
          </div>
          {/* Login Panel End */}
          {/* Background Image start */}
          <div className="w-full mt-0 mr-auto mb-0 min-h-[64.000em] absolute top-[91px] text-center z-0 block">
            <picture >
            <img
              src={backgroundImage}
              className="relative object-cover bg-center bg-cover bg-repeat w-full h-[64rem]"
              alt="Home"
              height="auto"
              width="100%"
            />
            </picture>
          </div>
          {/* Background Image End */}
        </div>
      </div>
      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
    </div>
  );
};
export default Login;
