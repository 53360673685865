import { AxiosResponse } from 'axios';
import IModule from './Module.interface';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import { APIResponse } from '../Helper/type';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class UpdateOrganizationAdminModule {
  public apiResponse: APIResponse | undefined;
  constructor(public userInfo: IModule) {}
  // Declaring the sub-path
  public subPath: string = '/organization/module';

  // PUT request for fetching data from db to Module list. This method is an array of type IModuleList or null
  public async updateModule(moduleList: IModule[]): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'PUT');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = moduleList;
      const response: AxiosResponse<any, any> = await axiosWithRoles.put(url, requestBody);
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
