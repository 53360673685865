export const AWS_SIGNIN_URL =
  'https://signin.aws.amazon.com/federation?Action=login&Destination=https://console.aws.amazon.com/codecommit/&SigninToken=';

export const removeDuplicates = (arr: any[]) => {
  const uniqueEmails = new Set();
  return arr.filter((obj) => {
    const isDuplicate = uniqueEmails.has(obj.email);
    uniqueEmails.add(obj.email);
    return !isDuplicate;
  });
};
