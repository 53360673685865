import React, { useEffect } from 'react';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import Button from '../../library/Button/button';
import { Link } from 'react-router-dom';
import { createLogoutChannel } from '../../utils/Helper/broadcastChannel';

interface LogoutConfirmationPopupProps {
  onClose: (e: boolean) => void;
  visibility: boolean;
  onCancelHandler: () => void;
  clearSession: () => void;
}
/* This component is basically used for logout confirmation popup .
props are used to handle the functionlity of custompopuop componenrts */
const LogoutConfirmationPopup: React.FC<LogoutConfirmationPopupProps> = ({
  onClose,
  visibility,
  onCancelHandler,
  clearSession
}) => {
  // Create a logout channel
  const logoutChannel = createLogoutChannel();
  useEffect(() => {
    const handleLogout = () => {
      clearSession();
      onClose(false);
    };

    logoutChannel.addEventListener('message', handleLogout);

    return () => {
      logoutChannel.removeEventListener('message', handleLogout);
    };
  }, [logoutChannel, clearSession, onClose]);
  return (
    <CustomPopup onClose={onClose} show={visibility}>
      <h2 className="text-lg font-medium mb-4">Are you sure you want to log out?</h2>
      <div className="flex justify-center">
        <Button
          className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black"
          buttonText="Cancel"
          onclick={onCancelHandler}
        />
        <Link
          className="border-0 py-2 pl-[1.5rem] pr-[-0.5rem] mx-[5px]"
          to="/login"
          onClick={clearSession}
        >
          <span className="text-[#2c323f] px-4 py-2.5 bg-white hover:bg-black hover:text-white rounded focus:outline-none border border-spacing-1 border-black">
            Logout
          </span>
        </Link>
      </div>
    </CustomPopup>
  );
};

export default LogoutConfirmationPopup;
