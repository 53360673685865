import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoLogoutPopup from './AutoLogoutMessage';

interface SessionTimeoutProps {
  timeoutInMinutes: number;
  onSessionTimeout: () => void;
  children: any;
}
// Here session timeout component manages the state of the session timer and renders a countdown timer or a redirect to the login page when the session expires.
const SessionTimeout: React.FC<SessionTimeoutProps> = ({
  timeoutInMinutes,
  onSessionTimeout,
  children
}) => {
  const [sessionExpired, setSessionExpired] = useState(false);

  // useEffect() hook to start the timer when it mounts and clear the timer when it unmounts or when the user logs out.
  useEffect(() => {
    let timerId: any;
    const timeoutInMs = timeoutInMinutes * 60 * 1000;
    const startTime = Date.now();

    const checkSessionTimeout = () => {
      const elapsedTime = Date.now() - startTime;
      if (elapsedTime >= timeoutInMs) {
        setSessionExpired(true);
        onSessionTimeout();
      } else {
        timerId = setTimeout(checkSessionTimeout, timeoutInMs - elapsedTime);
      }
    };

    timerId = setTimeout(checkSessionTimeout, timeoutInMs - (Date.now() - startTime));
    return () => clearTimeout(timerId);
  }, [timeoutInMinutes, onSessionTimeout]);

  // State to control the visibility of the auto-logout popup.
  const [showAutoLogoutPopup, setShowAutoLogoutPopup] = useState(true);
  const navigate = useNavigate();

  const handleAutoLogoutConfirm = () => {
    setShowAutoLogoutPopup(false);
    navigate('/login');
  };

  if (sessionExpired) {
    return <>{showAutoLogoutPopup && <AutoLogoutPopup onConfirm={handleAutoLogoutConfirm} />}</>;
  }

  return <>{children}</>;
};

export default SessionTimeout;
