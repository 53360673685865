import React, { Fragment, useState } from 'react';
import CustomPopup from './CustomPopup';
import IUserList from '../../utils/UserList/UserList.interface';
import { EditUserForm } from '../AdminPanel/EditUser';
import { DeleteUser } from '../AdminPanel/DeleteUser';
import ReactLoading from 'react-loading';
import ResetMfa from './ResetMfa';

/**
 * This common props takes in several parameters.
 * @usersData - These prop is likely used to store the users list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @show - These prop is used to show the userlist popup.
 * @setShow - These prop is used to update the @show based on user's input
 * @disabled - This prop is used to pass the disabled field in edituser and delete user form.
 **/

interface OrgUserProps {
  usersData: any[];
  query: string | null;
  disabled: boolean;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  onStatusDecrement?: any;
}
// component for showing Organization users for a specific Organization Admin.
export const OrgUserForm: React.FC<OrgUserProps> = ({
  usersData,
  query,
  disabled,
  show,
  setShow,
  isLoading,
  onStatusDecrement
}) => {
  // Method to close the popup on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
  };

  const getUserName = (userEmail: string) => {
    const updatedName = sessionStorage.getItem(`Update Name ${userEmail}`);
    return updatedName !== null
      ? updatedName
      : usersData.find((user) => user.email === userEmail).name;
  };

  const emailToDelete = sessionStorage.getItem('Email');

  const indexToDelete = usersData.findIndex(
    (item: { email: string | null }) => item.email === emailToDelete
  );

  if (indexToDelete !== -1) {
    usersData.splice(indexToDelete, 1);
  }

  return (
    <CustomPopup onClose={popupCloseHandler} show={show}>
      <div className="w-fit h-fit">
        <Fragment>
          <div className="flex start justify-between px-4 py-4 border-b border-black">
            <h4 className="mb-0 leading-normal text-2xl text-black">Partner Users</h4>
          </div>
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={200}
            />
          ) : (
            <div className="position-relative grow shrink basis-auto px-4 py-4 block max-h-[30rem]">
              {usersData.length > 0 ? (
                <table className="w-100%  border-collapse ">
                  <thead className="table-header-group align-middle bg-[#f8f8f8] ">
                    <tr className="text-black text-left table-row align-inherit">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        Users Name
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData
                      .filter((user: any) => user.name.toLowerCase().includes(query))
                      .map((dataSet: IUserList, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="px-6 py-4 text-center">
                              <div className="w-48">
                                <p className="text-ellipsis overflow-hidden">
                                  {getUserName(dataSet.email!)}
                                </p>
                              </div>
                            </td>
                            <td className="px-6 py-4 text-center">
                              {' '}
                              <EditUserForm
                                orgPartnerCompanyEmail={dataSet.orgEmail}
                                userName={getUserName(dataSet.email!)}
                                orgJobTitle={
                                  sessionStorage.getItem(`Updated Role ${dataSet.email}`) !== null
                                    ? sessionStorage.getItem(`Updated Role ${dataSet.email}`)
                                    : dataSet.role
                                }
                                orgPartnerCompanyName={dataSet.companyName}
                                userEmail={dataSet.email}
                                disabled={disabled}
                                userPhone={dataSet.phone}
                              />
                              <DeleteUser
                                userEmail={dataSet.email}
                                orgEmail={dataSet.orgEmail}
                                disabled={disabled}
                                onStatusDecrement={onStatusDecrement}
                              />
                              <ResetMfa userEmail={dataSet.email} disabled={disabled} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div
                  className="px-2 py-2 text-center"
                  style={{ paddingTop: '1rem', fontWeight: 'bold' }}
                >
                  <h1 className="text-bold">No users found under this partner ☹️ !!</h1>
                </div>
              )}
            </div>
          )}
        </Fragment>
      </div>
    </CustomPopup>
  );
};
