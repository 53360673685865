import { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import IOrgUser from './OrgUser.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path to call api
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
/* this class is used to call delete api, when user click on delete button it will
call this deleteOrgAdmin function and send the request to backend via axios. In request body we are sending
partener company name and email */
export default class DeleteOrganization {
  public apiResponse: APIResponse | undefined;

  constructor(public orgInfo: IOrgUser) {}
  // Declaring the sub-path
  public subPath: string = '/organization/admin';

  // DELETE request to delete an organization
  public async deleteOrgAdmin(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'DELETE');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.orgInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.delete(url, {
        data: requestBody
      });
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      console.log(error);
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path
  private createUrl(params?: string): string {
    return `${backEndServerUrl}${params}`;
  }
}
