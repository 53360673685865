import React, { useState, Fragment, useEffect, useCallback, useId } from 'react';
import CustomPopup from './CustomPopup';
import ImageTag from '../../library/ImageTag/ImageTag';
import edit from '../../assets/img/edit.png';
import UpdateOrganization from '../../utils/OrgUser/UpdateOrgAdmin';
import Form from '../../library/Form/Form';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import { JobTitle, Title, responseMessages } from '../../utils/Helper/enums';
import { wrapAsyncFunction } from '../UtilityFunction/wrapAsyncFunction';
import { useNavigate } from 'react-router-dom';
import Button from '../../library/Button/button';

interface editAdminUserProps {
  orgEmail: string | any;
  userTitle: string | any;
  userFname: string | any;
  userLname: string | any;
  orgJobTitle: string | any;
  orgPhone: string | any;
  orgPartnerCompanyName: string | any;
  orgCompanyHaedquaterAddress: string | any;
  orgDescription: string | any;
}
// Re-usable component for updating Organization users for a specific Organization Admin
export const EditAdminUserForm: React.FC<editAdminUserProps> = ({
  orgEmail,
  userTitle,
  userFname,
  userLname,
  orgJobTitle,
  orgPhone,
  orgPartnerCompanyName,
  orgCompanyHaedquaterAddress,
  orgDescription
}) => {
  // Interface for declaring data types
  interface formDetails {
    email?: string | null;
    title?: string | null;
    fullname?: string | null;
    lname?: string | null;
    jobTitle?: string | null;
    phone?: string | null;
    partnerCompanyName?: string | null;
    companyHaedquaterAddress?: string | null;
    description?: string | null;
  }
  const optionTitle = [
    { label: Title.Mr, value: Title.Mr },
    { label: Title.Mrs, value: Title.Mrs },
    { label: Title.Miss, value: Title.Miss },
    { label: Title.Ms, value: Title.Ms },
    { label: Title.Dr, value: Title.Dr },
    { label: Title.Prof, value: Title.Prof },
    { label: Title.Sir, value: Title.Sir }
  ];

  const optionJobTitle = [
    { label: JobTitle.Developer, value: JobTitle.Developer },
    { label: JobTitle.Admin, value: JobTitle.Admin }
  ];

  // ToDO: In later stages, we're planning to handle it from the popup.
  const [showFormEdit, setShowFormEdit] = useState(false);
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently the db value.
  const [email, setEmail] = useState(orgEmail);
  const [title, setTitle] = useState(userTitle);
  const [fullname, setFullname] = useState(userFname);
  const [lname, setLname] = useState(userLname);
  const [jobTitle, setJobTitle] = useState(orgJobTitle);
  const [phone, setPhone] = useState(orgPhone);
  const [partnerCompanyName, setPartnerCompanyName] = useState(orgPartnerCompanyName);
  const [companyHaedquaterAddress, setCompanyHeadQuarterAddress] = useState(
    orgCompanyHaedquaterAddress
  );
  const [description, setDescription] = useState(orgDescription);
  // Declaring state for handling errors.
  const [errors, setErrors] = useState<formDetails>({});
  const [showLoader, setShowLoader] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const navigate = useNavigate();
  const uniqueId = useId();

  // Form validation code added to useState
  useEffect(() => {
    validateForm();
  }, [fullname, lname, phone, companyHaedquaterAddress, description]);

  // Reset fields whenever showForm changes
  useEffect(() => {
    // Reset form fields when opening form
    if (showFormEdit) {
      setEmail(email);
      setFullname(fullname);
      setJobTitle(jobTitle);
      setPhone(phone);
      setPartnerCompanyName(partnerCompanyName);
      setCompanyHeadQuarterAddress(companyHaedquaterAddress);
      setDescription(description);
      setShowAlert(false);
    }
  }, [showFormEdit]);

  // Form validation code added here
  const validateForm = () => {
    const errors: formDetails = {};

    if (!fullname.trim()) {
      errors.fullname = 'Please provide a valid name';
    }
    if (!companyHaedquaterAddress.trim()) {
      errors.companyHaedquaterAddress = "Company address can't be empty";
    }
    if (!description.trim()) {
      errors.description = "Description can't be empty";
    }
    setErrors(errors);
  };

  // Storing data from UI to db via promise
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    sessionStorage.removeItem('CompanyName');
    sessionStorage.removeItem('Company Email');
    // To show the loader after submitting the form
    setTimeout(() => setShowLoader(false), 2000);
    setTimeout(() => setShowFormEdit(false), 20000);
    // Form submission code here
    const userObj = new UpdateOrganization({
      email,
      fullname,
      jobTitle,
      phone,
      partnerCompanyName,
      companyHaedquaterAddress,
      description
    });

    // API responses have been merged since actions are same
    const updateStatus = await userObj.updateOrgAdmin();
    setEmail(email);
    setFullname(fullname);
    setJobTitle(jobTitle);
    setPhone(phone);
    setPartnerCompanyName(partnerCompanyName);
    setCompanyHeadQuarterAddress(companyHaedquaterAddress);
    setDescription(description);

    const errorMessage = responseMessages.UserExists;
    const successMessage = responseMessages.UpdateUserSuccess;
    const response = handleApiResponse(updateStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setResponseMessage(responseBody);
    setShowAlert(true);

    if (updateStatus.statusCode === 201) {
      navigate('/btadmin');
    }
  };

  // Wrap the handleButtonClick function in useCallback to show the update user form on clicking the edit button
  const handleButtonClick = useCallback(() => {
    setShowFormEdit((prevShowFormEdit) => !prevShowFormEdit);
  }, []);

  // Wrap the popupCloseHandler function in useCallback to close the create user form on clicking the close button
  const popupCloseHandler = useCallback(() => {
    setShowFormEdit(false);
  }, []);

  return (
    <Fragment>
      <Button
        id={`edit-organisation-${uniqueId}`}
        aria-label="Edit Organisation"
        onclick={handleButtonClick}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <ImageTag src={edit} width={'20px'} alt={''} style={{ marginLeft: '1rem' }} name={'Edit'} />
        {isShowing && (
          <div className="group flex relative">
            <span
              className="px-1 py-1 group-hover:opacity-100 transition-opacity text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 absolute -top-24 left-1/2
       -translate-x-1/2 translate-y-full m-4 mx-auto"
            >
              Edit Organisation
            </span>
          </div>
        )}
      </Button>
      <CustomPopup onClose={popupCloseHandler} show={showFormEdit}>
        <Form
          orgEmail={email}
          userTitle={title}
          setTitle={setTitle}
          userFname={fullname}
          setFname={setFullname}
          userLname={lname}
          setLname={setLname}
          orgJobTitle={jobTitle}
          setJobTitle={setJobTitle}
          orgPhone={phone}
          setPhone={setPhone}
          orgPartnerCompanyName={partnerCompanyName}
          orgCompanyHaedquaterAddress={companyHaedquaterAddress}
          setCompanyHeadQuarterAddress={setCompanyHeadQuarterAddress}
          orgDescription={description}
          setDescription={setDescription}
          showEditForm={showAlert}
          setShowFormEdit={setShowFormEdit}
          message={responseMessage}
          showLoader={showLoader}
          errors={errors}
          onSubmit={wrapAsyncFunction(onSubmit)}
          optionTitle={optionTitle}
          optionJobTitle={optionJobTitle}
          showFormEdit={showFormEdit}
          isBtAdmin={false}
          usersEmail={''}
          isFeedback={false}
          createUser={function (e: React.FormEvent<Element>): void {
            throw new Error('Function not implemented.');
          }}
        />
      </CustomPopup>
    </Fragment>
  );
};
