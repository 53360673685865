// Importing necessary modules
import { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import { handleApiResponseConsoleLogin } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import IConsoleLogin from './ConsoleLogin.interface';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
// Defining the User class
export default class AwsLogin {
  public apiResponse: APIResponse | undefined; // A public property to store the API response

  // Declaring the constructor to get the request body.
  constructor(public userInfo: IConsoleLogin) {}

  // Declaring the sub-path
  public subPath: string = '/consolelogin';

  // POST request for sending data to aws federation endpoint through backend. This methods returns a response body along with a status code.
  public async consoleLogin(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      this.apiResponse = handleApiResponseConsoleLogin(response);
    } catch (error: any) {
      switch (error?.response?.status) {
        case 400:
          this.apiResponse = {
            statusCode: 400,
            body: 'Link is not valid.'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path
  private createUrl(params?: string): string {
    return `${backEndServerUrl}${params}`;
  }
}
