import React, { Fragment } from 'react';
import Card from './Card';
import microprocessor from '../assets/img/microprocessor.png';
import router from '../assets/img/router.png';
import api_gear from '../assets/img/api_gear.png';
import cubes from '../assets/img/cubes.png';
import { CardContent } from '../../src/utils/Helper/enums';

const AreasOfExperties: React.FC<any> = (props: any) => {
  return (
    <Fragment>
      <Card
        title="Silicon providers"
        content={CardContent.SiliconProviders}
        image={microprocessor}
      />
      <Card title="Device manufacturers" content={CardContent.DeviceManufacturers} image={router} />
      <Card
        title="Specialist software providers"
        content={CardContent.SpecialistSoftwareProviders}
        image={api_gear}
      />
      <Card
        title="3rd party app suppliers"
        content={CardContent.ThirdPartyAppSuppliers}
        image={cubes}
      />
    </Fragment>
  );
};

export default AreasOfExperties;
