import React, { Fragment, useState } from 'react';
import BtLogo from '../../library/Logo/BtLogo';
import LeftPanel from '../AdminPanel/LeftPanel';
import Profile from './Profile';

// Header component for Admin and BtAdmin pages
const AdminTopBar: React.FC<any> = () => {
  // states to change the background color in logo
  const [isLogout, setIsLogout] = useState<boolean>(false);
  return (
    <Fragment>
      <div
        className={
          isLogout
            ? 'w-[300px] h-[6rem] inline-block text-left left-0 top-0 z-[52] fixed'
            : 'w-[300px] h-[6rem] inline-block text-left left-0 bg-white top-0 z-[52] fixed'
        }
      >
        <div className="flex w-full h-full justify-start items-center text-white px-[50px]">
          <br />
          <BtLogo />
        </div>
      </div>
      {/* LeftPanel is a component which contains the left navbar data for admin and BT Admin pages */}
      <LeftPanel />
      <div className="fixed top-0 w-full h-[6rem] p-0  z-[51] pl-[300px] bg-[#f8f8f8]">
        <div className="flex justify-between items-center h-full py-[22px] px-[30px] float-left" />
        <div className="flex justify-between items-center h-full py-[22px] px-[30px] float-right">
          <div className="flex items-center">
            <div
              className={
                isLogout
                  ? 'bg-[#f8f8f8] py-2.5 px-3 relative rounded-[5px]'
                  : 'bg-white py-2.5 px-3 relative rounded-[5px]'
              }
            >
              <div className="p-0 block outline-0">
                {/* Here the Profile is a component which contains the Admin and BT Admin User info section
                    i.e., Admin/BtAdmin name, profile along with a logout button. */}
                <Profile setIsLogout={setIsLogout} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminTopBar;
