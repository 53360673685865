import React from 'react';

interface IHeading {
  id?: string;
  headerText: string;
  className: string;
}

export const IPPHeading: React.FC<IHeading> = ({ id, headerText, className }) => {
  return (
    <h1 id={id} className={className}>
      {headerText}
    </h1>
  );
};

export const IPPSubHeading: React.FC<IHeading> = ({ id, headerText, className }) => {
  return (
    <h2 id={id} className={className}>
      {headerText}
    </h2>
  );
};

export const IPPFourthLevelHeading: React.FC<IHeading> = ({ id, headerText, className }) => {
  return (
    <h4 id={id} className={className}>
      {headerText}
    </h4>
  );
};

export const IPPParagraph: React.FC<IHeading> = ({ id, headerText, className }) => {
  return (
    <p id={id} className={className}>
      {headerText}
    </p>
  );
};
