// Parent Header component that can accept any child header component as a prop and render it.
import React, { Fragment, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Header: React.FC<Props> = ({ children }) => {
  return (
    <Fragment>
      <div>{children}</div>
    </Fragment>
  );
};

export default Header;
