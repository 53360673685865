import React, { useState } from 'react';
import ImageTag from '../library/ImageTag/ImageTag';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import login from '../assets/img/login.png';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import SendVerificationCode from '../utils/PasswordRsest/sendVerificationCode';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import ResetPasswordForm from './ResetPasswordForm';
import Button from '../library/Button/button';
import UserLogin from '../utils/UserLogin/UserLogin';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
// This component is responsible to send verfication code to user email if user forgot their password and want to reset their password.
const ForgotPassword: React.FC<any> = (props: any) => {
  const navigate: NavigateFunction = useNavigate();
  const [codeStatus, setCodeStatus] = useState(false);
  const [email, setEmail] = useState('');
  const [show, setShow] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userPass, setUserPass] = useState('');
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  // Method to check the entered user email is valid or not and if valid then store it in a state.
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(enteredEmail)) {
      setEmailError('Invalid email format');
      setIsFormValid(false);
    } else {
      setEmailError('');
      setIsFormValid(true);
    }
  };
  // storing user email to session storage for reseting password
  sessionStorage.setItem('userEmail', email);

  // Method to send verification code to user email.
  const handleForgotPassword = async () => {
    setShowAlert(true);
    setShow(!show);
    const sendVerificationCodeObj = new SendVerificationCode({
      email
    });
    const sendCodeStatus = await sendVerificationCodeObj.sendCode();
    setEmail(email);
    switch (sendCodeStatus.statusCode) {
      case 201:
        setResponseMessage('sent code successfully');
        break;
      case 400:
        setResponseMessage(sendCodeStatus.body);
        setIsResend(true);
        break;
      case 404:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 429:
        setResponseMessage(sendCodeStatus.body);
        break;
      default:
        setResponseMessage('Failed to send code');
        break;
    }
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };

  // Resend mail for user who wants to sign-in to Organization User page
  const resendMail = async () => {
    setShowLoader(true);
    setShowAlert(true);
    setShow(!show);
    const userLoginObj = new UserLogin({ email: email || '', password: userPass || '' });
    const res = await userLoginObj.resendUrl();
    setShowLoader(false);
    switch (res.statusCode) {
      case 201:
        setResponseMessage('Verification Link has been sent to your email');
        setEmail('');
        setUserPass('');
        setIsResend(false);
        break;
      default:
        setResponseMessage('Failed to send verification link');
        setEmail('');
        setUserPass('');
        setIsResend(false);
        break;
    }
  };
  // method to close popup.
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShow(e);
    if (!codeStatus) {
      navigate(0);
    }
  };
  // method to handle enter key.
  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isFormValid) {
      event.preventDefault();
      await handleForgotPassword();
    }
  };

  return (
    <div>
      <div className="m-0 p-0 align-baseline">
        <div className="z-10 pt-3.5 min-h-[900px]  clear-both w-full relative overflow-hidden">
          <Header>
            <WelcomeHeader />
          </Header>
          <div
            className="w-full  absolute z-50 mt-0 clear-both h-px"
            style={{
              borderTop: '1px solid #ccc'
            }}
          ></div>
          <div className="clear-both"></div>
          <div className="w-[980px] mx-auto mt-0 mb-0 px-4 min-h-[380px] min-w-[288px] table z-[5] relative top-[100px]">
            <div className="block p-[0.5rem] rounded-lg shadow-lg mt-[129px] my-8 mx-[19.625rem] pointer-events-auto float-none justify-center  bg-white">
              {!codeStatus ? (
                <div className="p-4">
                  <div className="w-full">
                    <div className="text-[22px] text-center mb-[15px]">Send Verification Code</div>
                    <div className="mt-2">
                      {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
                    </div>

                    <InputField
                      id={'VerifiedEmail'}
                      className={`h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
                        emailError ? 'border-red' : ''
                      }`}
                      type="email"
                      placeholder="Enter your verified email"
                      value={email}
                      onChange={handleEmailChange}
                      onKeyUp={handleKeyUp}
                    />
                  </div>
                  <Button
                    className="mt-2 content-center p-0 tracking-normal mb-4 h-10 text-[#fff] text-[17px] w-full shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]"
                    onclick={wrapAsyncFunction(handleForgotPassword)}
                    disabled={!isFormValid}
                    buttonText="Send Code"
                  />
                  <CustomPopup onClose={popupCloseAlertHandler} show={showAlert}>
                    <div className="max-h-30%">
                      <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
                      {isResend && (
                        <Button
                          className="my-2 mx-4 h-[33px] text-[#fff] text-[16px] w-60 cursor-pointer bg-black rounded-[10px]"
                          buttonText="Resend verification email"
                          onclick={wrapAsyncFunction(resendMail)}
                          loading={showLoader}
                          disabled={showLoader}
                        />
                      )}
                    </div>
                  </CustomPopup>
                </div>
              ) : (
                <div>
                  <CustomPopup onClose={popupCloseAlertHandler} show={showAlert}>
                    <div className="max-h-30%">
                      <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
                    </div>
                  </CustomPopup>
                  <ResetPasswordForm />
                </div>
              )}
            </div>
          </div>
          <div className="w-full mt-0 mr-auto mb-0  min-h-[64.000em] absolute top-[91px] text-center z-0  block">
            <ImageTag
              src={login}
              className="relative object-cover bg-center bg-cover bg-repeat"
              alt="Home"
              height="auto"
              width="100%"
              name={'Login'}
            />
          </div>
        </div>
      </div>
      <div className="z-[9999]">
        <Footer />
      </div>
    </div>
  );
};
export default ForgotPassword;
