import React, { Suspense, useEffect, useState } from 'react';
import edit from '../../../assets/img/edit.png';
import bin from '../../../assets/img/bin.png';
import ImageTag from '../../../library/ImageTag/ImageTag';
import CreatePresetForm from './CreatePresetForm';
import Preset from '../../../utils/ModulePresets/Preset';
import { IModuleAceess, IPreset } from '../../../utils/ModulePresets/preset.interface';
import ReactLoading from 'react-loading';
import EditPresetForm from './EditPresetForm';
import ShowPreset from './ShowPreset';
import ConfirmationPopUp from '../../../library/ConfirmationPopUp/ConfirmationPopUp';
import { IPPParagraph } from '../../../library/Heading/Heading';
import Button from '../../../library/Button/button';
import CustomPopup from '../CustomPopup';
import IModule from '../../../utils/Module/Module.interface';

interface IProps {
  allModules: IModule[];
}

// To show the list of all presets available
const PresetModules = ({ allModules }: IProps) => {
  const customModules = allModules.filter((module) => module.isSystemModule);

  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [showModuleListPopup, setShowModuleListPopup] = useState(false);
  const [showEditModuleListPopup, setShowEditModuleListPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [presets, setPresets] = useState<IPreset[] | []>([]);
  const [isUpdated, setIsUpdated] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirmation, setshowDeleteConfirmation] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedPreset, setSelectedPreset] = useState<IPreset>();

  const handleCloseCreatePopup = (i: boolean) => {
    setShowCreatePopup(i);
  };

  const cancelDelete = () => {
    setshowDeleteConfirmation(false);
  };

  const confirmDelete = async (presetName: string) => {
    setIsDeleting(true);
    try {
      const presetInst = new Preset();
      await presetInst.delete(presetName);
      setIsUpdated(true);
      setshowDeleteConfirmation(false);
      setAlertMessage('Deleted Successfully');
    } catch (error) {
      console.error(error);
      setAlertMessage('Something went wrong');
    }
    setIsDeleting(false);
  };

  const getCount = (item: IModuleAceess[]) => {
    return item.filter((i: IModuleAceess) => {
      return customModules.findIndex((mod) => mod.moduleName === i.moduleName) === -1;
    }).length;
  };

  useEffect(() => {
    const fetchPrestes = async () => {
      setIsLoading(true);
      try {
        const presetInst = new Preset();
        const presets = (await presetInst.getAllPreset()) ?? [];
        setPresets(presets?.filter((preset) => preset.presetName) ?? []);
      } catch (error) {
        console.error('Error loading data:', error);
      }
      setIsLoading(false);
    };
    if (isUpdated) {
      void fetchPrestes();
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <Suspense
      fallback={
        <div className="w-full flex justify-center align-middle items-center">
          <ReactLoading
            className="flex justify-center my-[4rem]"
            type="bubbles"
            color="#000000"
            height={100}
            width={350}
          />
        </div>
      }
    >
      <div className="w-full md:w-full">
        <div className="bg-white block md:w-[100%] mx-auto md:ps-0.5 md:pe-0.5 pr-72">
          <br />
          <hr />
          <br />
          <div className="flex justify-end">
            <Button
              className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
              onclick={() => {
                setShowCreatePopup(true);
              }}
            >
              Create Preset
            </Button>
          </div>
          <br />
          <div className="w-full max-h-screen overflow-y-scroll">
            <div>
              {isLoading ? (
                <div className="flex w-full h-full justify-center items-center">
                  <ReactLoading
                    className="flex justify-center my-[4rem]"
                    type="bubbles"
                    color="#000000"
                    height={100}
                    width={350}
                  />
                </div>
              ) : presets.length ? (
                <table className="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-center">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Preset Name{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Number of Modules{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Action{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {presets.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-center">{item.presetName}</td>
                        <td className="px-6 py-4 text-center">
                          <Button
                            onclick={() => {
                              setSelectedPreset(item);
                              setShowModuleListPopup(true);
                            }}
                          >
                            {getCount(item.moduleList)}
                          </Button>
                        </td>
                        <td className="px-6 py-4 text-center">
                          <Button
                            onclick={() => {
                              setSelectedPreset(item);
                              setShowEditModuleListPopup(true);
                            }}
                          >
                            <ImageTag
                              key="Edit"
                              src={edit}
                              width={'20px'}
                              alt={''}
                              style={{ marginTop: '-2.4rem', marginLeft: '2rem', margin: 'auto' }}
                              name={''}
                            />
                          </Button>
                          <Button
                            onclick={() => {
                              setSelectedPreset(item);
                              setshowDeleteConfirmation(true);
                            }}
                          >
                            <ImageTag
                              key="Delete"
                              src={bin}
                              width={'20px'}
                              alt={''}
                              style={{ marginTop: '-2.4rem', marginLeft: '2rem', margin: 'auto' }}
                              name={''}
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center">No Preset Found !!</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {showEditModuleListPopup && selectedPreset ? (
        <EditPresetForm
          preset={selectedPreset}
          show={showEditModuleListPopup}
          onClose={() => {
            setSelectedPreset(undefined);
            setShowEditModuleListPopup(false);
          }}
          setIsUpdated={setIsUpdated}
        />
      ) : (
        <></>
      )}
      {showModuleListPopup && selectedPreset ? (
        <ShowPreset
          preset={selectedPreset}
          show={showModuleListPopup}
          onClose={() => {
            setSelectedPreset(undefined);
            setShowModuleListPopup(false);
          }}
          setIsUpdated={setIsUpdated}
        />
      ) : (
        <></>
      )}
      {showDeleteConfirmation && selectedPreset ? (
        showDeleteConfirmation && (
          <ConfirmationPopUp show={showDeleteConfirmation}>
            <div className="max-h-30% ">
              <IPPParagraph
                headerText={`Do you want to delete - ${selectedPreset.presetName}`}
                className={'mr-0 mb-0.5 ml-0 text-[23px]'}
              />
              <br />
              <div className="flex justify-center">
                <Button
                  className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
                  buttonText="Yes"
                  loading={isDeleting}
                  disabled={isDeleting}
                  onclick={() => {
                    void confirmDelete(selectedPreset.presetName);
                  }}
                />
                <Button
                  className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
                  buttonText="No"
                  onclick={cancelDelete}
                />
              </div>
            </div>
          </ConfirmationPopUp>
        )
      ) : (
        <></>
      )}
      <CustomPopup
        show={alertMessage.length > 0}
        onClose={() => {
          setAlertMessage('');
        }}
      >
        <IPPParagraph className="" headerText={alertMessage} />
      </CustomPopup>
      {showCreatePopup && (
        <CreatePresetForm
          show={showCreatePopup}
          onClose={handleCloseCreatePopup}
          setIsUpdated={setIsUpdated}
        />
      )}
    </Suspense>
  );
};

export default PresetModules;
