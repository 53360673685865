import React, { useState, useEffect } from 'react';
import { LoginContextType, LoginContext } from './IppContext';

interface Props {
  children?: React.ReactNode;
}

const LoginContextProvider: React.FC<Props> = ({ children }) => {
  const [loginToken, setLoginToken] = useState<string>(() => {
    // Retrieve the login token from sessionStorage if it exists
    const storedToken = sessionStorage.getItem('loginToken');
    return storedToken ?? '';
  });

  const updateToken = (value: string) => {
    setLoginToken(value);
  };

  useEffect(() => {
    // Save the login token to sessionStorage whenever it changes
    sessionStorage.setItem('loginToken', loginToken);
  }, [loginToken]);

  const contextValue: LoginContextType = {
    loginToken,
    updateToken
  };

  return <LoginContext.Provider value={contextValue}>{children}</LoginContext.Provider>;
};

export default LoginContextProvider;
