import IModule from './Module.interface';
import IModuleList from '../ModuleList/ModuleList.interface';
// Helper class to store all the Module's data from db to an array
export const ModuleList = (arrAdmin: Array<IModule | null>): Array<IModuleList | null> => {
  // Declaring the array as of type IModuleList or null
  const arrUserList: Array<IModuleList | null> = [];
  // Mapping the data from db to the array of type IModule or null
  arrAdmin.forEach((element: IModule | null, index) => {
    if (element) {
      arrUserList.push({
        key: index.toString(),
        moduleName: `${element.moduleName}`,
        partner: `${element.noOfPartner}`,
        buttonStatus: null,
        orgAccessList: element.orgAccessList,
        isSystemModule: element.isSystemModule,
        name: null,
        email: null,
        role: null,
        status: null,
        progress: null,
        icon: undefined
      });
    }
  });
  return arrUserList;
};
