import axios, { AxiosResponse } from 'axios';
import IUserList from '../UserList/UserList.interface';
import { adminUserList } from './OrganizationUser.helper';
import IOrganizationUser from './OrganizationUser.interface';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class AllOrganizationAdmin {
  constructor(public userInfo: IOrganizationUser) {}
  // Declaring the sub-path
  public subPath: string = `/organization/admin/${this.userInfo.email}`;
  public jwtToken = sessionStorage.getItem('jwtToken');
  public apiKey = String(process.env.REACT_APP_API_KEY ?? '');
  // creating header to wrap during the api call.
  public config = {
    headers: {
      Authorization: this.jwtToken,
      'x-api-key': this.apiKey
    }
  };

  // GET request for fetching data from db to Organization User. This method is an array of type IUserList or null
  public async getAllAdminUser(): Promise<Array<IUserList | null>> {
    try {
      const url: string = this.createUrl(this.subPath);
      const response: AxiosResponse<any, any> = await axios.get(url, this.config);
      const arrAdmin: Array<IOrganizationUser | null> = [];
      response.data.orgUser.forEach((element: IOrganizationUser) => {
        arrAdmin.push(element);
      });
      return adminUserList(arrAdmin);
    } catch (error: any) {
      alert(error);
    }
    return await this.getAllAdminUser();
  }

  // Binding the Webserver path and sub-path

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
