import React, { useState } from 'react';
import { Puff } from 'react-loading-icons';
import InputField from '../library/inputField/inputField';
import Button from '../library/Button/button';

// this a reusable component  for submitting the OTP for login and validation.
interface OtpValidateProps {
  onOtpSubmission: Function;
  isLoading: boolean;
}

const OtpValidate: React.FC<OtpValidateProps> = ({ onOtpSubmission, isLoading }) => {
  const [userCode, setUserCode] = useState<string>('');

  return (
    <div className="flex flex-col justify-center items-start">
      <div className="text-[22px] text-start mb-[15px]">Please enter OTP</div>
      <InputField
        id={'userCode'}
        className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
        type="text"
        placeholder="This is usually a 6 digit OTP"
        maxLength={6}
        onChange={(e) => setUserCode((e.target as HTMLInputElement).value)}
      />
      <div className="flex flex-1 flex-row justify-center w-full">
        {isLoading ? (
          <Puff stroke="#000000" />
        ) : (
          <Button
            onclick={() => {
              onOtpSubmission(userCode);
            }}
            className={
              userCode === ''
                ? 'bg-transparent w-36 hover:bg-blue-500 text-[#A9A9A9] font-semibold hover:text-[#A9A9A9] mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded cursor-not-allowed'
                : 'bg-transparent w-36 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded'
            }
            disabled={userCode === ''}
            buttonText="Submit"
          />
        )}
      </div>
    </div>
  );
};

export default OtpValidate;
