import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import { IPreset } from './preset.interface';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
// API Request handler class
export default class Preset {
  public subPath: string = '/module/preset';
  private callCount = 0;

  public jwtToken = sessionStorage.getItem('jwtToken');
  public apiKey = String(process.env.REACT_APP_API_KEY ?? '');
  public config = {
    headers: {
      Authorization: this.jwtToken,
      'x-api-key': this.apiKey
    }
  };

  private readonly genrateResponse = (
    response: { status: number; message?: string },
    successMessage?: string,
    errorMessage?: string
  ): { status: number; message: string } => {
    let status = 500;
    let message = 'Something went wrong';
    if (response.status === 200 || response.status === 201) {
      status = response.status;
      message = successMessage ?? 'Success';
    } else if (response.status === 400) {
      status = 400;
      if (response.message === 'moduleList empty') {
        message = 'Please select modules';
      } else {
        message = errorMessage ?? message;
      }
    }
    return { status, message };
  };

  public async getAllPreset(): Promise<IPreset[] | null> {
    try {
      const axiosWithRoles = apiCallWrapper(this.subPath, 'GET');
      const url = this.createUrl(this.subPath);
      const response = await axiosWithRoles.get(url, this.config);
      const data = response.data;
      this.callCount = 0;
      return data;
    } catch (error) {
      console.error(error);
      if (this.callCount < 2) {
        this.callCount += 1;
        return await this.getAllPreset();
      } else {
        return await new Promise((resolve, reject) => {
          return resolve(null);
        });
      }
    }
  }

  public async getPreset(name: string): Promise<IPreset | null> {
    try {
      const axiosWithRoles = apiCallWrapper(this.subPath, 'GET');
      const url = this.createUrl(this.subPath) + `/${name}`;
      const response = await axiosWithRoles.get(url, this.config);
      const data = response.data;
      this.callCount = 0;
      return data;
    } catch (error) {
      console.error(error);
      if (this.callCount < 2) {
        this.callCount += 1;
        return await this.getPreset(name);
      } else {
        return await new Promise((resolve, reject) => {
          return resolve(null);
        });
      }
    }
  }

  public async create(preset: IPreset | {}) {
    try {
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url = this.createUrl(this.subPath);
      const response = await axiosWithRoles.post(url, preset, this.config);
      return this.genrateResponse(
        {
          status: response.status ?? 201
        },
        'Created Successfully'
      );
    } catch (error: any) {
      console.error(error);
      return this.genrateResponse(
        {
          status: error?.response?.status ?? 500,
          message: error?.response?.data ?? ''
        },
        '',
        'Preset already exist'
      );
    }
  }

  public async update(preset: IPreset) {
    try {
      const axiosWithRoles = apiCallWrapper(this.subPath, 'PUT');
      const url = this.createUrl(this.subPath) + `/${preset?.presetName ?? ''}`;
      const response = await axiosWithRoles.put(url, preset, this.config);
      return this.genrateResponse(
        {
          status: response.status ?? 200
        },
        'Updated Successfully'
      );
    } catch (error: any) {
      console.error(error);
      return this.genrateResponse(
        {
          status: error?.response?.status ?? 500,
          message: error?.response?.data ?? ''
        },
        '',
        error?.response?.data
      );
    }
  }

  public async delete(name: string) {
    try {
      const axiosWithRoles = apiCallWrapper(this.subPath, 'DELETE');
      const url = this.createUrl(this.subPath) + `/${name}`;
      await axiosWithRoles.delete(url, this.config);
    } catch (error) {
      console.error(error);
    }
  }

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
