import { Link } from 'react-router-dom';
import React from 'react';

const ResponsiveMenu = (props: any): any => {
  return (
    <div
      className={
        props.active
          ? 'absolute z-50 bg-white bg-w-60 ml-24 origin-right order-2 shadow-none  text-black w-full  duration-500 md:hidden'
          : 'absolute z-50 bg-white bg-w-60 ml-24 origin-right order-2 shadow-none  text-black w-full  duration-500'
      }
      style={props.active ? { transform: '' } : { transform: 'scaleX(0)' }}
    >
      <ul>
        <li className="py-4 ml-3 font-bold">
          <Link to="/">About</Link>
        </li>
        <li className="py-4 ml-3 font-bold">
          <Link to="/login">Sign-in</Link>
        </li>
      </ul>
    </div>
  );
};

export default ResponsiveMenu;
