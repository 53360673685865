import { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import IChangePassword from './ChangePassword.interface';

// Declaring the web server path to call api
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
/* this class is used to call change password api, when user click on change password button */
export default class PasswordChangeBtAdmin {
  public apiResponse: APIResponse | undefined;
  constructor(public userInfo: IChangePassword) {}
  // Declaring the sub-path
  public subPath: string = '/changepasswordbtadmin';

  // Post request to reset user password with verfication code.
  public async changePassword(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      switch (error?.response?.status) {
        case 400:
          this.apiResponse = {
            statusCode: 400,
            body: 'UserId or Password invalid.'
          };
          break;
        case 404:
          this.apiResponse = {
            statusCode: 404,
            body: 'User not found.'
          };
          break;
        case 403:
          this.apiResponse = {
            statusCode: 403,
            body: 'User not verified,please verify fist.'
          };
          break;
        case 401:
          this.apiResponse = {
            statusCode: 401,
            body: 'Access token not found in response.'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path
  private createUrl(params?: string): string {
    return `${backEndServerUrl}${params}`;
  }
}
