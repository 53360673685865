import React, { Fragment } from 'react';
import UserRow from '../UserRow';
import Search from '../Search';
import ReactLoading from 'react-loading';
import IUserList from '../../utils/UserList/UserList.interface';
import { IPPHeading } from '../../library/Heading/Heading';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 **/
interface ModuleListProps {
  key: number;
  data: any[];
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  numValuesToShow: string;
  handleNumValuesChange: any;
}

// ModuleList is a functional component which displays a table of repositories that organisation has access to.
const ModuleList: React.FC<ModuleListProps> = ({
  key,
  data,
  isLoading,
  query,
  setQuery,
  numValuesToShow,
  handleNumValuesChange
}) => {
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <IPPHeading
            headerText={'Indigo Modules'}
            className={'text-black text-xl leading-tight font-medium mb-2'}
          />
          <br />
          <hr />
          <br />
          <Search onChange={(e: any) => setQuery(e.target.value)} text="Search by access..." />
          {/* Todo - fix with next release */}
          {/* <Show onChange={handleNumValuesChange} /> */}
          <br />
          <br />
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={500}
            />
          ) : (
            <div className="w-full h-[300px] overflow-y-auto">
              {data.length > 0 ? (
                <table className="mx-auto max-w-4xl w-full table-fixed whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                  <thead className="bg-[#f8f8f8]">
                    <tr className="text-black text-left">
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Module Name{' '}
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Access
                      </th>
                      <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                        {' '}
                        Action{' '}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data
                      .filter((dataSet: IUserList) =>
                        dataSet.moduleList.some((info: any) =>
                          info.accessType.toLowerCase().includes(query.toLowerCase())
                        )
                      )
                      .map((dataSet: IUserList, index: number) => (
                        <Fragment key={index}>
                          {dataSet.moduleList
                            ? dataSet.moduleList
                                .filter((info: any) =>
                                  info.accessType.toLowerCase().includes(query.toLowerCase())
                                )
                                .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                                  a.moduleName.localeCompare(b.moduleName)
                                )
                                .slice(
                                  0,
                                  numValuesToShow === ''
                                    ? dataSet.moduleList.length
                                    : numValuesToShow
                                )
                                .map((info: any) => (
                                  <UserRow
                                    key={info.moduleName}
                                    name={info.moduleName}
                                    email={null}
                                    role={null}
                                    status={null}
                                    level={info.accessType}
                                    progress={null}
                                    icon={undefined}
                                    module={null}
                                    buttonStatus={null}
                                    companyName={''}
                                    orgEmail={''}
                                    onCompanyEmailChange={function (email: string): void {
                                      throw new Error('Function not implemented.');
                                    }}
                                    onCompanyNameChange={function (name: string): void {
                                      throw new Error('Function not implemented.');
                                    }}
                                    disabled={true}
                                  />
                                ))
                            : null}
                        </Fragment>
                      ))}
                    {data.filter((dataSet: IUserList) =>
                      dataSet.moduleList.some((info: any) =>
                        info.accessType.toLowerCase().includes(query.toLowerCase())
                      )
                    ).length === 0 && (
                      <tr>
                        <td className="px-6 py-4 pl-[7rem] text-center">
                          <div
                            className="px-6 py-4 text-center"
                            style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                          >
                            <IPPHeading
                              headerText={'No data found ☹️ !!'}
                              className={'text-bold'}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <IPPHeading headerText={'No data found ☹️ !!'} className={'text-bold'} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ModuleList;
