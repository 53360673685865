import React, { Dispatch, Fragment, useEffect, useState } from 'react';
import CustomPopup from '../CustomPopup';
import ReactLoading from 'react-loading';
import ModuleAccessList from './ModuleAccessList';
import { IPPHeading, IPPParagraph } from '../../../library/Heading/Heading';
import Button from '../../../library/Button/button';
import apiCallWrapper from '../../../apiwrapper/apiCallWrapper';
import { IPreset } from '../../../utils/ModulePresets/preset.interface';
import { ModuleAccessLevel } from '../../../utils/Helper/enums';
import Preset from '../../../utils/ModulePresets/Preset';
import IModule from '../../../utils/Module/Module.interface';

interface Props {
  show: boolean;
  onClose: (set: boolean) => void;
  preset: IPreset;
  setIsUpdated: Dispatch<React.SetStateAction<boolean>>;
}
// Edit preset
// show and onclose - to handel popup display
// preset - presetname and module access list
// setIsUpdate - to Update parent component about changes
const EditPresetForm = ({ show, onClose, preset, setIsUpdated }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [baseModules, setBaseModules] = useState<[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAccessTypes, setSelectedAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );
  const [defaultAccessType, setDefaultAccessTypes] = useState<{ [moduleName: string]: string }>({});
  const [submitMessage, setSubmitMessage] = useState('Please Wait...');

  const resetState = () => {
    setSubmitMessage('Please Wait...');
    setIsSubmitting(false);
  };

  const submitEditForm = async () => {
    setIsSubmitting(true);
    const moduleList = Object.keys(selectedAccessTypes)
      .filter(
        (moduleName) =>
          selectedAccessTypes[moduleName] !== ModuleAccessLevel.NOACCESS &&
          baseModules.findIndex((item: IModule) => item.moduleName === moduleName) !== -1
      )
      .map((moduleName) => ({
        moduleName,
        accessType: selectedAccessTypes[moduleName]
      }));
    const presetObject = {
      presetName: preset.presetName ?? '',
      moduleList
    };
    try {
      const presetInst = new Preset();
      const response = await presetInst.update(presetObject);
      setSubmitMessage(response.message);
      if (response?.status === 200 || response?.status === 201) {
        setTimeout(() => {
          setIsUpdated(true);
          resetState();
          onClose(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      setSubmitMessage('Somethig went wrong');
    }
  };

  const closePopup = () => {
    resetState();
    setIsUpdated(true);
    onClose(false);
  };

  useEffect(() => {
    // Fetch all modules
    const fetchModule = async () => {
      setIsLoading(true);
      try {
        const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
        const subPath = '/organization/module';
        const url = `${backEndServerUrl}/organization/module`;
        const Axios = await apiCallWrapper(subPath, 'GET');
        const response = await Axios.get(url);
        const allModules = response.data;
        // Filter modules where isSystemModule is false
        const nonSystemModules = allModules.filter(
          (module: { isSystemModule: boolean }) => !module.isSystemModule
        );
        setBaseModules(nonSystemModules);
      } catch (error) {
        console.error('Error fetching system modules:', error);
      }
      setIsLoading(false);
    };
    void fetchModule();
  }, []);
  // set selected modules access
  useEffect(() => {
    const initilSelect: { [moduleName: string]: string } = {};
    preset.moduleList.forEach((element) => {
      initilSelect[element.moduleName] = element.accessType;
    });
    setSelectedAccessTypes(initilSelect);
    setDefaultAccessTypes(initilSelect);
  }, [preset]);

  return (
    <CustomPopup show={show} onClose={onClose}>
      <Fragment>
        <div>
          <div>
            <IPPHeading className="font-bold text-2xl" headerText={preset.presetName} />
          </div>
          <br />
          {isLoading ? (
            <ReactLoading
              className="flex justify-center my-[4rem]"
              type="bubbles"
              color="#000000"
              height={100}
              width={350}
            />
          ) : (
            <Fragment>
              <ModuleAccessList
                name={preset.presetName}
                baseModules={baseModules}
                selectedAccessTypes={selectedAccessTypes}
                setSelectedAccessTypes={setSelectedAccessTypes}
                defaultAccessTypes={defaultAccessType}
                systemModules={[]}
              />
              <div className="sticky bottom-[-16px] bg-white p-4 inset-x-0">
                <Button
                  className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2"
                  buttonText="Submit"
                  onclick={submitEditForm}
                  loading={isSubmitting}
                  disabled={isSubmitting || !preset.presetName}
                />
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
      <CustomPopup show={isSubmitting} onClose={closePopup}>
        <IPPParagraph className="" headerText={submitMessage} />
      </CustomPopup>
    </CustomPopup>
  );
};

export default EditPresetForm;
