import React, { useState } from 'react';
import ChildTab from '../../library/Tab/tab';
import { useNavigate } from 'react-router-dom';

// Defining the interface for tab object
interface ITab {
  label: string;
  key: string;
}

// Defining the interface for the Tabs component props
interface TabsProps {
  tabs: ITab[];
}

/* Render a set of tabs, where each tab represents a different section of content.
It receives an array of tab objects (tabs) as props, where each tab object contains a label and a key. */
const TabsComponent: React.FC<TabsProps> = ({ tabs }) => {
  /* useState hook from React to manage the active tab state.
  By default, it sets the first tab as the active tab (activeTabKey). */
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key);
  const navigate = useNavigate();

  /* When a tab is clicked, the handleTabClick function is called,
  updating the activeTabKey state with the key of the clicked tab. */
  const handleTabClick = (key: string) => {
    setActiveTabKey(key);
  };

  /* The handleSubmit function is triggered when a form submission occurs within the Tab component,
  and it uses the useNavigate hook from React Router to navigate to the '/register?btadmin=true' URL. */
  const handleSubmit = () => {
    navigate('/register?btadmin=true');
  };

  // Rendering the Tab component with necessary props
  return (
    <ChildTab
      tabs={tabs}
      activeTabKey={activeTabKey}
      onTabClick={handleTabClick}
      onSubmit={handleSubmit}
    />
  );
};

export default TabsComponent;
