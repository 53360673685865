import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import PasswordChange from '../utils/PasswordChange/changePassword';
import PasswordChangeForm from '../components/passwordChange';
import PasswordChangeBtAdmin from '../utils/PasswordChange/changePasswordBtAdmin';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
interface changePasswordProps {
  userEmail: string | any;
}
// This component is responsible to to change the users password , user have to enter their verified email, old password and new password.
const ChangePassword: React.FC<changePasswordProps> = ({ userEmail }) => {
  const navigate: NavigateFunction = useNavigate();
  const [codeStatus, setCodeStatus] = useState(false);
  const [email, setEmail] = useState(userEmail);
  const [show, setShow] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const [otpError, setOtpError] = useState('');
  // Useeffect to handle side effects.
  useEffect(() => {
    // Validate password policy.
    if (newPassword) {
      if (currentPassword === newPassword) {
        setPasswordError('New Password can not same as current password');
      } else {
        setPasswordError('');
      }
    }
  }, [newPassword, currentPassword]);
  useEffect(() => {
    if (confirmPassword) {
      // Validate password confirmation
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    }
  }, [confirmPassword, newPassword]);

  useEffect(() => {
    if (otp.length > 6) {
      setOtpError('OTP should be 6 digits only');
    } else {
      setOtpError('');
    }
  }, [otp]);
  // Toggle password show and hide functions.
  const togglecurrentPassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';
  const buttonText1 = confirmPasswordShown ? 'Hide' : 'Show';
  const buttonText2 = currentPasswordShown ? 'Hide' : 'Show';
  // Method to set entered new password.
  const handlecurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };
  // Method to set entered new password.
  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };
  // Method to set entered confirm password.
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  // Method to set entered OTP.
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };
  // Method to check the entered user email is valid or not and if valid then store it in a state.
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(enteredEmail)) {
      setEmailError('Invalid email format');
      //   setIsFormValid(false);
    } else {
      setEmailError('');
      //   setIsFormValid(true);
    }
  };

  // Method to send verification code to user email.
  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowAlert(true);
    setShow(!show);
    const changePasswordObj = new PasswordChange({
      email,
      currentPassword,
      newPassword,
      otp
    });
    const sendCodeStatus = await changePasswordObj.changePassword();
    setEmail(email);
    switch (sendCodeStatus.statusCode) {
      case 201:
        setResponseMessage('Password changed successfully');
        break;
      case 400:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 403:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 404:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 410:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 409:
        setResponseMessage(sendCodeStatus.body);
        break;
      default:
        setResponseMessage('Something went wrong. Please try after sometime');
        break;
    }
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };
  const handleChangePasswordBtAdmin = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowAlert(true);
    setShow(!show);
    const changePasswordObj = new PasswordChangeBtAdmin({
      email,
      currentPassword,
      newPassword
    });
    const sendCodeStatus = await changePasswordObj.changePassword();
    setEmail(email);
    switch (sendCodeStatus.statusCode) {
      case 201:
        setResponseMessage('Password changed successfully');
        break;
      case 400:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 403:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 404:
        setResponseMessage(sendCodeStatus.body);
        break;
      case 401:
        setResponseMessage(sendCodeStatus.body);
        break;
      default:
        setResponseMessage('Failed to change password');
        break;
    }
    if (sendCodeStatus.statusCode === 201) {
      setCodeStatus(true);
    }
  };

  // method to close popup.
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShow(e);
    if (codeStatus) {
      navigate('/login');
    } else {
      navigate(0);
    }
  };
  // method to close popup.
  const popupCloseFormHandler = (e: boolean) => {
    setShowForm(false);
    navigate(0);
  };
  const isEmailEmpty = email.trim() === '';
  const isNewPassword = newPassword.trim() === '';
  const isCurrentPassword = currentPassword.trim() === '';
  const isconfirmPassword = confirmPassword.trim() === '';
  const isOtp = otp.trim() === '';
  const location = useLocation();
  const disabledButton =
    !emailError &&
    !passwordError &&
    !confirmPasswordError &&
    !isEmailEmpty &&
    !isNewPassword &&
    !isCurrentPassword &&
    !isconfirmPassword &&
    !isOtp &&
    !otpError;

  const disabledButtonBtAdmin =
    !emailError &&
    !passwordError &&
    !confirmPasswordError &&
    !isEmailEmpty &&
    !isNewPassword &&
    !isCurrentPassword &&
    !isconfirmPassword;

  const formValid = location.pathname === '/btadmin' ? disabledButtonBtAdmin : disabledButton;
  const changePassword =
    location.pathname === '/btadmin' ? handleChangePasswordBtAdmin : handleChangePassword;
  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && formValid) {
      event.preventDefault();
      await handleChangePassword(event);
    }
  };
  return (
    <CustomPopup onClose={popupCloseFormHandler} show={showForm}>
      <form onSubmit={wrapAsyncFunction(changePassword)}>
        <div className="w-72">
          <div className="text-[22px] text-center mb-[15px]">Change Password</div>
          <div className="mt-2">
            {emailError && <p className="text-red-500 text-xs">{emailError}</p>}
          </div>
          <InputField
            id={'email'}
            className="text-light-gray h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
            type="email"
            value={email}
            onKeyUp={handleKeyUp}
            readOnly
          />
          <Link
            className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
            onClick={(e) => togglecurrentPassword(e)}
            to={''}
          >
            {buttonText2}
          </Link>
          <InputField
            id={'currentPassword'}
            className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
            type={currentPasswordShown ? 'text' : 'password'}
            placeholder="Enter your current password"
            value={currentPassword}
            onChange={handlecurrentPasswordChange}
          />
          <PasswordChangeForm
            password={newPassword}
            confirmPassword={confirmPassword}
            onPasswordChange={handleNewPasswordChange}
            onConfirmPasswordChange={handleConfirmPasswordChange}
            passwordError={passwordError}
            confirmPasswordError={confirmPasswordError}
            passwordShown={passwordShown}
            togglePassword={togglePassword}
            confirmPasswordShown={confirmPasswordShown}
            toggleConfirmPassword={toggleConfirmPassword}
            formValid={formValid}
            buttonText={buttonText}
            buttonText1={buttonText1}
            otp={otp}
            otpError={otpError}
            onOtpChange={handleOtpChange}
            buttonName="Change Password"
          />
        </div>
      </form>
      <CustomPopup onClose={popupCloseAlertHandler} show={showAlert}>
        <div className="max-h-30%">
          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
        </div>
      </CustomPopup>
    </CustomPopup>
  );
};
export default ChangePassword;
