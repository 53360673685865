import React, { useState } from 'react';
import UnlockUser from '../../utils/UserLogin/UnlockUsers';
import { useNavigate } from 'react-router-dom';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import Button from '../../library/Button/button';

interface UserData {
  key: string;
  email: string;
}

interface LockedUsersProps {
  lockedUsers: UserData[];
}

const LockedUsersList: React.FC<LockedUsersProps> = ({ lockedUsers }) => {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const handleUnlockClick = (email: string) => {
    setShow(false);
    setShowAlert(true);
    const onUnlockHandler = async (email: string) => {
      const unlockUserObj = new UnlockUser({ email });
      const result = await unlockUserObj.unlockUser();
      if (result.statusCode === 201) {
        setResponseMessage(`${email} unlocked successfully`);
      } else {
        setResponseMessage('Failed to unlock the user');
      }
    };
    void onUnlockHandler(email);
  };
  const onCloseHandler = (e: boolean) => {
    setVisibility(e);
  };
  const onUnlockHandler = () => {
    setShow(true);
  };
  const onCancelHandler = () => {
    setVisibility(!visibility);
    setShow(!show);
  };
  // Method to close the popup on clicking the close button
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setShow(e);
    navigate(0);
  };
  return (
    <div className="w-full h-auto overflow-y-auto">
      <table className="mx-auto max-w-md w-full table-fixed whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
        <thead className="bg-[#f8f8f8]">
          <tr className="text-black text-left">
            <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Email </th>
            <th className="font-semibold text-sm uppercase px-6 py-4 text-center"> Action </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          <tr className="text-black">
            <td className="px-6 py-4 text-center">
              <ul>
                {lockedUsers.map((user) => (
                  <li className="py-2" key={user.key}>
                    <span>{user.email}</span>
                  </li>
                ))}
              </ul>
            </td>
            <td className="px-6 py-4 text-center">
              <ul>
                {lockedUsers.map((user) => (
                  <li className="py-2" key={user.key}>
                    <div className="md:ml-14 lg:ml-14 sm:ml-16 sm:pl-4 md:pl-2 lg:pl-6 flex">
                      <Button onclick={onUnlockHandler}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      </Button>
                      {show && (
                        <CustomPopup onClose={onCloseHandler} show={show}>
                          <h2 className="text-lg font-medium mb-4">
                            Are you sure you want to Unlock?
                          </h2>
                          <div className="flex justify-center">
                            <Button
                              className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
                              buttonText="Cancel"
                              onclick={onCancelHandler}
                            />
                            <Button
                              className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
                              onclick={() => handleUnlockClick(user.email)}
                              buttonText="Unlock"
                            />
                          </div>
                        </CustomPopup>
                      )}
                      {showAlert && (
                        <CustomPopup onClose={popupCloseAlertHandler} show={showAlert}>
                          <div className="max-h-30%">
                            <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
                          </div>
                        </CustomPopup>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LockedUsersList;
