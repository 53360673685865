import React, { Fragment, useEffect, useState } from 'react';
import UserRow from '../UserRow';
import Search from '../Search';
import IUserList from '../../utils/UserList/UserList.interface';
import ReactLoading from 'react-loading';
import Pagination from '../../library/Pagination/Pagination';

/**
 * This common props takes in several parameters.
 * @isLoading - Indicates whether data is being fetched from the back-end, used to show a loader.
 * @itemsPerPage - Number of items to display per page in pagination.
 * @currentPage - Current page number in the pagination.
 * @handlePageChange - Callback function to handle page changes in pagination.
 * @filteredData - Data to be displayed, filtered based on search and pagination.
 * @showPagination - Flag indicating whether to show pagination.
 * @currentDataPartnerAdmin - Data representing the current set of BT Admin users to be displayed.
 * @resetPageAndSearch - Callback function to reset the page and perform a search based on a new query.
 * @needExtraPadding - Extra CSS required for padding.
 **/
interface UserListProp {
  isLoading: boolean;
  itemsPerPage: number;
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
  filteredData: any;
  showPagination: boolean;
  currentDataPartnerAdmin: any;
  resetPageAndSearch: (newQuery: string) => void;
  needExtraPadding: any;
}
// React functional component that displays a list of BT Admin users in a table format with a search bar and a dropdown to change the number of users to display.
const UserList: React.FC<UserListProp> = ({
  isLoading,
  itemsPerPage,
  currentPage,
  handlePageChange,
  filteredData,
  showPagination,
  currentDataPartnerAdmin,
  resetPageAndSearch,
  needExtraPadding
}) => {
  const [userCounts, setUserCounts] = useState<any>({});
  const [storedLength, setStoredLength] = useState<any>({});
  const companyToRemove = sessionStorage.getItem('CompanyName');
  const emailToRemove = sessionStorage.getItem('Company Email');
  sessionStorage.setItem('UserList Length', currentDataPartnerAdmin.length);
  const indexToRemove = currentDataPartnerAdmin.findIndex(
    (item: { companyName: string | null; email: string | null }) =>
      item.companyName === companyToRemove && item.email === emailToRemove
  );

  if (indexToRemove !== -1) {
    currentDataPartnerAdmin.splice(indexToRemove, 1);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedValue = sessionStorage.getItem('Module Length');
      if (storedValue !== null) {
        const moduleId = sessionStorage.getItem('Organisation Name');
        setStoredLength((prevValues: any) => ({
          ...prevValues,
          [moduleId!]: parseInt(storedValue)
        }));
      }
    }, 1000); // check every 1 second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    currentDataPartnerAdmin.forEach((dataSet: IUserList) => {
      if (!userCounts[dataSet.email!]) {
        setUserCounts((prevUserCounts: any) => ({
          ...prevUserCounts,
          [dataSet.email!]: Number(dataSet.status) // Initialize the count if user doesn't exist
        }));
      }
    });
  }, [currentDataPartnerAdmin]);

  const handleStatusIncrement = (email: string) => {
    sessionStorage.setItem('Users Data Length', userCounts[email].toString());
    if (!userCounts[email]) {
      setUserCounts((prevUserCounts: any) => ({
        ...prevUserCounts,
        [email]: 1 // Initialize the count if user doesn't exist
      }));
    } else {
      setUserCounts((prevUserCounts: any) => ({
        ...prevUserCounts,
        [email]: userCounts[email] + 1 // Increment the count if user exists
      }));
    }
  };

  const handleStatusDecrement = (email: string) => {
    sessionStorage.setItem('Users Data Length', userCounts[email].toString());
    setUserCounts((prevUserCounts: any) => ({
      ...prevUserCounts,
      [email]: prevUserCounts[email] ? prevUserCounts[email] - 1 : 0 // Decrement the count correctly
    }));
  };

  return (
    <Fragment>
      <div className="w-full md:w-full">
        <div className="bg-white block md:w-[100%] mx-auto md:ps-0.5 md:pe-0.5 pr-72">
          <br />
          <hr />
          <br />
          <Search
            onChange={(e: any) => resetPageAndSearch(e.target.value)}
            text="Search by partner..."
          />
          {/* Todo - fix with next release */}
          {/* <Show value={numValuesToShow} onChange={handleNumValuesChange} /> */}
          <br />
          <br />
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={1350}
            />
          ) : (
            <div className="w-full max-h-screen overflow-y-scroll">
              {filteredData.length > 0 ? (
                <div>
                  <table className="w-full whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                    <thead className="bg-[#f8f8f8]">
                      <tr className="text-black text-center">
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          Partner Name{' '}
                        </th>
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          Admin User Name{' '}
                        </th>

                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          No. of Users{' '}
                        </th>
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          Assigned Modules{' '}
                        </th>
                        <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                          {' '}
                          Action{' '}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {currentDataPartnerAdmin.map((dataSet: IUserList) => (
                        <UserRow
                          key={dataSet.key}
                          name={dataSet.companyName!}
                          email={dataSet.email!}
                          level={dataSet.level!}
                          progress={dataSet.progress!}
                          icon={dataSet.icon}
                          module={storedLength[dataSet.companyName!] || dataSet.module!}
                          buttonStatus={dataSet.buttonStatus!}
                          role={dataSet.role!}
                          status={userCounts[dataSet.email!] || dataSet.status!}
                          title={dataSet.title}
                          fname={dataSet.fullname}
                          lname={dataSet.lname}
                          jobTitle={dataSet.jobTitle}
                          phone={dataSet.phone}
                          description={dataSet.description}
                          companyHaedquaterAddress={dataSet.companyHaedquaterAddress}
                          moduleList={dataSet.moduleList}
                          companyName={''}
                          orgEmail={''}
                          onCompanyEmailChange={function (email: string): void {
                            throw new Error('Function not implemented.');
                          }}
                          onCompanyNameChange={function (name: string): void {
                            throw new Error('Function not implemented.');
                          }}
                          disabled={false}
                          domains={dataSet.domains}
                          onStatusIncrement={() => handleStatusIncrement(dataSet.email!)}
                          onStatusDecrement={() => handleStatusDecrement(dataSet.email!)}
                        />
                      ))}
                    </tbody>
                  </table>
                  {showPagination && filteredData.length > itemsPerPage && (
                    <Pagination
                      className={`pagination-bar ${needExtraPadding} flex justify-center items-center m-auto`}
                      currentPage={currentPage}
                      totalCount={filteredData.length}
                      pageSize={itemsPerPage}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <h1 className="text-bold">No data found ☹️ !!</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
