import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useNetworkConnection = () => {
  const navigate = useNavigate();

  const handleConnectionChange = () => {
    if (navigator.onLine) {
      // User is back online, refresh the page
      navigate(0);
    }
  };

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      // Cleanup: remove event listeners when component is unmounted
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, [navigate]); // Include navigate in the dependency array to avoid any stale closure issues

  return {
    isConnected: navigator.onLine
  };
};

export default useNetworkConnection;
