import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 **/
interface ModuleGridProps {
  key: number;
  data: any[];
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  numValuesToShow: string;
  handleNumValuesChange: any;
}

// ModuleGrid is a functional component which displays a grid of repositories that organisation has access to.
const ModuleGrid: React.FC<ModuleGridProps> = ({
  key,
  data,
  isLoading,
  query,
  setQuery,
  numValuesToShow,
  handleNumValuesChange
}) => {
  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div style={{ paddingInline: '2px 2px', width: '100%' }}>
          <h1 className="text-black text-xl leading-tight font-medium">Indigo Modules</h1>
          <br />
          <hr />
          <br />
          {isLoading ? (
            // show the loader here
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={500}
            />
          ) : (
            <div className="w-full h-[400px] overflow-y-auto">
              {data.length > 0 ? (
                <div className="grid grid-cols-4 gap-4 md:gap-80 md:gap-y-6 lg:gap-64 lg:gap-y-6 xl:gap-16 xl:gap-y-6">
                  {data
                    .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                      a.moduleName.localeCompare(b.moduleName)
                    )
                    .map((item: any, index: number) => (
                      <div
                        key={index}
                        className="mr-4 block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700"
                        style={{ width: 288 }}
                      >
                        <div className="p-6">
                          <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
                            {item.moduleName}
                          </h5>
                          <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                            <span className="font-semibold">Access permission:</span>{' '}
                            {item.accessType}
                          </p>
                          <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 break-words">
                            <span className="font-semibold">Clone command:</span> git clone
                            codecommit://&lt;profile&gt;@
                            {item.moduleName} {item.moduleName}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div
                  className="px-6 py-4 text-center"
                  style={{ paddingTop: '5rem', fontWeight: 'bold' }}
                >
                  <h1 className="text-bold">No data found ☹️ !!</h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ModuleGrid;
