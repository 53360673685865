import { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import IOrgUser from './OrgUser.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path to call api
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
/* this class is used to call delete api, when user click on delete button it will
call this deleteUser function and send the request to backend via axios. In request body we are sending
partener company email and user email */
export default class DeleteUsers {
  public apiResponse: APIResponse | undefined;
  public userEmail = sessionStorage.getItem('Email');

  constructor(public userInfo: IOrgUser) {}
  // Declaring the sub-path
  public subPath: string = `/organization/user/${this.userEmail}`;

  // DELETE request to delete an organization
  public async deleteUser(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'DELETE');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.delete(url, {
        data: requestBody
      });
      const errorMessage = '';
      const successMessage = '';
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      console.log(error);
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }

  // Binding the Webserver path and sub-path
  private createUrl(params?: string): string {
    return `${backEndServerUrl}${params}`;
  }
}
