import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { UserType } from '../utils/Helper/enums';
import AdminPanel from '../pages/AdminPanel';
import BtAdminPanel from '../pages/BtAdminPanel';
import UserPanel from '../pages/UserPanel';
import Register from '../pages/Register';

/**
 * This method is responsible for handling protected routes within the application.
 * @admin , @btadmin and @userPanel - These three routes are being recalled as protected routes as these routes are the important part of the business aspect.
 * The variable @userType is utilized to store the current path of the user logging in, particularly when Single Sign-On (SSO) is being employed.
 * @userType - These variable is storing the current path, the user is logging in. (Note: Since we're using SSO).
 * The purpose of checking the validity of @userType is to ensure that only authorized users are permitted to access their respective designated routes.
 * In the event that @user attempts to log in as a specific user but subsequently manipulates the URL to access another protected route, the application is designed to address this scenario by redirecting the user back to the @login page.
 * This implementation significantly enhances the security of our application, as it actively prevents unauthorized access to sensitive areas and safeguards the integrity of our business processes.
 **/
export const ProtectedRoutes = () => {
  const userType = sessionStorage.getItem('user-type');
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Redirect to login page if user type is not set
    if (!userType && location.pathname === '/register') {
      navigate('/login');
    } else if (
      (userType === String(UserType.OrganizationAdmin) &&
        (location.pathname === '/btadmin' ||
          location.pathname === '/user' ||
          location.pathname === '/register')) ||
      (userType === String(UserType.BtAdmin) &&
        (location.pathname === '/admin' || location.pathname === '/user')) ||
      (userType === String(UserType.OrganizationUser) &&
        (location.pathname === '/admin' ||
          location.pathname === '/btadmin' ||
          location.pathname === '/register'))
    ) {
      navigate('/login');
      sessionStorage.clear();
      navigate(0);
    }
  }, [location.pathname]);

  if (userType === String(UserType.OrganizationAdmin)) {
    return (
      <Routes>
        <Route path="/admin" element={<AdminPanel />} />
      </Routes>
    );
  } else if (userType === String(UserType.BtAdmin)) {
    return (
      <Routes>
        <Route path="/btadmin" element={<BtAdminPanel />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    );
  } else if (userType === String(UserType.OrganizationUser)) {
    return (
      <Routes>
        <Route path="/user" element={<UserPanel />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/*" element={<Navigate to={'/login'} />} />
      </Routes>
    );
  }
};
