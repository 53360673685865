import React from 'react';
import Button from '../../library/Button/button';
import { IPPHeading } from '../../library/Heading/Heading';

/**
 * AutoLogoutPopup Component
 *
 * This React component displays a popup message to inform the user that their session
 * has expired, and they have been logged out. It provides a button for the user to log
 * in again. The `onConfirm` prop is a callback function that should be triggered when
 * the user clicks the "Login" button.
 *
 * @param {object} props - The component props.
 * @param {function} props.onConfirm - Callback function to handle the login button click.
 *
 * @returns {JSX.Element} - Returns the JSX element for the auto logout popup.
 */

interface AutoLogoutPopupProps {
  onConfirm: () => void;
}

const AutoLogoutPopup: React.FC<AutoLogoutPopupProps> = ({ onConfirm }) => {
  return (
    <div className="popup fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center justify-center bg-white">
        <IPPHeading
          headerText={'Your session has expired. You have been logged out.'}
          className={'text-5xl text-center mb-4'}
        />
        <br />
        <IPPHeading
          headerText={'Please click the button below to log in again'}
          className={'text-2xl text-center mb-8'}
        />
        <Button
          className="bg-black text-white py-2 px-8 rounded-lg cursor-pointer"
          onclick={onConfirm}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default AutoLogoutPopup;
