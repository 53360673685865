import React from 'react';

interface StylingProps {
  condition: string | boolean | number;
  trueStyle: any;
  falseStyle: any;
  children: any;
  isSaveUserDetails: boolean;
  isSaveUserPcd: boolean;
}
export const ConditionalStyleComponent: React.FC<StylingProps> = ({
  condition,
  trueStyle,
  falseStyle,
  children
}) => {
  const style = condition ? trueStyle : falseStyle;
  return <div style={style}>{children}</div>;
};

export const DynamicHeader: React.FC<StylingProps> = ({
  isSaveUserDetails,
  isSaveUserPcd,
  children
}) => {
  let className;
  const style = { borderTop: '1px solid #fff' };

  if (isSaveUserDetails && isSaveUserPcd) {
    className =
      'py-5 px-[30px] my-0 -mx-[30px] bg-[#008A00] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]';
  } else if (isSaveUserDetails && !isSaveUserPcd) {
    className =
      'py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]';
  } else {
    className =
      'py-5 px-[30px] my-0 -mx-[30px] bg-[#ddd] text-[#333] cursor-pointer text-[1em] z-[1] min-h-[45px]';
  }

  return (
    <h2 className={className} style={style}>
      {children}
    </h2>
  );
};
