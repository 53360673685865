import { AxiosResponse } from 'axios';
import { APIResponse } from '../Helper/type';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import IFederatedIdentity from './FederatedIdentity.interface';

const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export class FederatedIdentity {
  constructor(public federatedidentityInfo: IFederatedIdentity) {}
  public apiResponse!: APIResponse;
  public getCredentialsPath: string = '/getcredentials';
  public async fetchAWSCredentials(
    idToken: string,
    userEmail: string,
    cognitoPrefredRole: string
  ): Promise<APIResponse> {
    try {
      const axiosWithRoles = apiCallWrapper(this.getCredentialsPath, 'POST');
      const url: string = this.createUri(this.getCredentialsPath);
      const requestBody: any = this.federatedidentityInfo;
      requestBody.idToken = idToken;
      requestBody.userEmail = userEmail;
      requestBody.cognitoPrefredRole = cognitoPrefredRole;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      this.apiResponse = {
        statusCode: 201,
        body: response ?? ''
      };
    } catch (error) {
      this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
    }
    return this.apiResponse;
  }

  private createUri(paramsResendUrl?: string): string {
    return backEndServerUrl + paramsResendUrl;
  }
}
