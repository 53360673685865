import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Verification from '../utils/User/Verify';
// Declearing interface for props.
interface ValidateCodeProps {
  email: string | any;
  token: string;
  onSetCodeStatus: (codeValid: boolean) => void;
  newstatus: boolean;
  isExpire: boolean;
}
// Component to show loader .
const Loader = ({ className }: any) => (
  <div className={className}>
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231   0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475   2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464    1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
        stroke="white"
      />
    </svg>
  </div>
);
// this component is responsible to verify user when they click on the link. Email & token are passed as props for api call and others props are used to hold the values of parent component.
const ValidateCode: React.FC<ValidateCodeProps> = ({
  email,
  token,
  onSetCodeStatus,
  newstatus,
  isExpire
}) => {
  const [responseMessage, setResponseMessage] = useState<string>('Verifying user....');
  const navigate: NavigateFunction = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  // UseEffect hook to handle side effects and invoke userVerification method.
  useEffect(() => {
    // checking if the email , token and link is valid.
    if (newstatus && isExpire) {
      // this method is responsible to verify user.
      const userVerification = async () => {
        setShowLoader(true);
        const userObj = new Verification({ email, token });
        const confirmStatus = await userObj.doValidate();
        setShowLoader(false);
        switch (confirmStatus.statusCode) {
          case 201:
            setResponseMessage('Registration successful, please setup your password');
            break;
          case 400:
            setResponseMessage(confirmStatus.body);
            break;
          case 401:
            setResponseMessage(confirmStatus.body);
            break;
          case 404:
            setResponseMessage(confirmStatus.body);
            break;
          case 409:
            setResponseMessage(confirmStatus.body);
            break;
          case 500:
            setResponseMessage(confirmStatus.body);
            break;
          default:
            setResponseMessage('Failed to verify user');
            break;
        }
        if (confirmStatus.statusCode === 201) {
          onSetCodeStatus(true);
        }
      };
      void userVerification();
    }
  }, [newstatus, email, token, isExpire]);
  return (
    <div>
      <div className="w-full">
        <div className="text-[22px] text-center mb-[15px]">
          {isExpire ? responseMessage : 'Link has Expired or Not valid.'}
        </div>
        {showLoader && <Loader className="spinner" />}
      </div>
      &nbsp;
    </div>
  );
};

export default ValidateCode;
