import { useMemo } from 'react';

// Constant representing the ellipsis used for indicating skipped page numbers
export const DOTS = '...';

// Helper function to generate a range of numbers from start to end (inclusive)
const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

/**
 * Custom hook for pagination.
 *
 * @param {Object} props - Pagination props.
 * @param {number} props.totalCount - Total number of items to be paginated.
 * @param {number} props.pageSize - Number of items to display per page.
 * @param {number} [props.siblingCount=1] - Number of additional page numbers to display on each side of the current page.
 * @param {number} props.currentPage - The current active page.
 * @returns {Array} - Array representing the range of page numbers to display.
 */

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: any) => {
  const paginationRange = useMemo(() => {
    // Calculate the total number of pages based on totalCount and pageSize. Here totalCount is tableData.length and pageSize is itemsPerPage
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Define the total number of page numbers to be displayed, including ellipses as siblingCount + firstPage + lastPage + currentPage + 2*DOTS.
    const totalPageNumbers = siblingCount + 5;

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate the left and right sibling indices based on the current page and siblingCount
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
      We do not want to show dots if there is only one position left
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // Handle scenarios for showing ellipsis on one or both sides
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
