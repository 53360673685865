import React, { createContext, useState } from 'react';

interface ICompanyNameProvide {
  children: React.ReactNode;
}

const CompanyNameContext = createContext<any>([]);

const CompanyNameProvider: React.FC<ICompanyNameProvide> = ({ children }) => {
  const [companyNameArray, setCompanyNameArray] = useState<any>([]);

  return (
    <CompanyNameContext.Provider value={{ companyNameArray, setCompanyNameArray }}>
      {children}
    </CompanyNameContext.Provider>
  );
};

export { CompanyNameProvider, CompanyNameContext };
