import React from 'react';

const NotFoundPage: React.FC<any> = () => {
  const clearSessionStorage = () => {
    sessionStorage.clear();
  };
  return (
    <div className="popup h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-5xl text-center mb-4">404 - Page Not Found</h1>
        <br />
        <h1 className="text-2xl text-center mb-8">Oops, you've found a dead link.</h1>
        <p className="list-[upper-roman] text-center text-lg py-2 px-8 cursor-pointer">
          <span>
            Go back to the{' '}
            <a href="/" className="underline text-indigo-500" onClick={clearSessionStorage}>
              login
            </a>{' '}
            page
          </span>
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
