import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FeedbackForm } from '../Feedback/Feedback';

const LeftPanel: React.FC<any> = () => {
  const userEmail = sessionStorage.getItem('userEmail');

  const location = useLocation();
  const isAdmin = location.pathname === '/admin';
  const btAdmin = location.pathname === '/btadmin';
  const isUser = location.pathname === '/user';

  const adminFullName = sessionStorage.getItem('adminFullName');
  const userName = sessionStorage.getItem('userName');

  const name = isAdmin ? adminFullName : isUser ? userName : null;

  return (
    <Fragment>
      {btAdmin ? (
        <div className="fixed left-0 z-[5] w-[300px] h-[100%] top-[5rem] pt-0 pb-[100px] bg-[#f8f8f8] box-border">
          <nav className="mb-10 mt-0">
            <ul className="pt-[30px]">
              <li className="relative px-[16px]">
                <Link
                  className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
                  to={''}
                >
                  {/* To do, span class need to be changed here */}
                  <span className="text-trans cursor-not-allowed">
                    {' '}
                    Manage Licenses/Agreements{' '}
                  </span>
                </Link>
              </li>
              <li className="relative px-[16px]">
                <Link
                  className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
                  to={''}
                >
                  {/* To do, span class need to be changed here */}
                  <span className="text-trans cursor-not-allowed"> Documentation </span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <div className="fixed left-0 z-[5] w-[300px] h-[100%] top-[5rem] pt-0 pb-[100px] bg-[#f8f8f8] box-border">
          <nav className="mb-10 mt-0">
            <ul className="pt-[30px]">
              <li className="relative px-[16px]">
                <Link
                  className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
                  to={''}
                >
                  {/* To do, span class need to be changed here */}
                  <span className="text-trans cursor-not-allowed">
                    {' '}
                    Manage Licenses/Agreements{' '}
                  </span>
                </Link>
              </li>
              <li className="relative px-[16px]">
                <Link
                  className="flex items-center text-black py-[15px] px-[20px] w-full relative no-underline hover:text-iris"
                  to={''}
                >
                  {/* To do, span class need to be changed here */}
                  <span className="text-trans cursor-not-allowed"> Documentation </span>
                </Link>
              </li>
              <li className="relative px-[16px]">
                <FeedbackForm orgEmail={userEmail} name={name} />
              </li>
            </ul>
          </nav>
        </div>
      )}
    </Fragment>
  );
};

export default LeftPanel;
