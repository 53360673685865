import React, { FormEvent } from 'react';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
/**
 * Search Component
 *
 * This Reusable React component filters the table based on user input.
 *
 * @param {object} text - The initial text for the search input placeholder.
 * @param {function} onChange - Event handler for input changes.
 *
 * @returns {JSX.Element} - Returns the JSX element for the reusable search component.
 */
interface SearchProps {
  text: string;
  onChange: (e: FormEvent<Element>) => void;
}
// Reusable search component to filter the table based on user input.
const Search: React.FC<SearchProps> = ({ text, onChange }) => {
  // ToDo: Add functionality to handle submission, if needed.
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <form action="/" method="get" onSubmit={handleSubmit}>
      <div className="float-right">
        <Label className="visually-hidden text-[#7E7E7E]" LabelText="Search: " />
        <InputField
          id={'header-search'}
          type="text"
          placeholder={text}
          className="border-black p-[4.8px 4.8px] rounded-lg pl-[6px]"
          style={{ border: '1px solid gray' }}
          name="s"
          onChange={onChange}
        />
      </div>
    </form>
  );
};

export default Search;
