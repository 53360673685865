import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
import Button from '../library/Button/button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IPPHeading, IPPSubHeading } from '../library/Heading/Heading';

const TermsAndConditions: React.FC<any> = () => {
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('userEmail')) {
      navigate('/login');
    }
  }, []);

  return (
    <div className="w-full min-h-screen bg-gray-100">
      <Header>
        <WelcomeHeader />
      </Header>
      <div className="flex flex-col items-center justify-center px-4 py-8 md:px-8 md:py-16 lg:px-16 lg:py-24">
        <IPPHeading
          headerText={'Indigo Partner Portal'}
          className={'text-2xl font-bold text-center mb-6 lg:text-4xl lg:mb-8'}
        />
        <IPPSubHeading
          headerText="Please read our terms conditions thoroughly and carefully. And accept it to proceed further."
          className={'text-lg text-center mb-8 lg:text-xl lg:mb-12'}
        />
        <div className="w-full max-w-md">
          <iframe src="/IndigoPortalUserTerms.pdf" width="100%" height="500px" />
        </div>
        <div className="flex flex-row justify-start mt-6  lg:mt-8">
          <InputField
            type="checkbox"
            className="text-blue-600"
            value="I accept the terms and conditions."
            checked={isAgreed}
            onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
              setIsAgreed(!isAgreed);
            }}
            id={'moduleName'}
          />
          <Label
            className="text-right  ml-2.5 float-left"
            LabelText="I accept the terms and conditions."
          />
        </div>
        <div className="flex flex-row justify-center mt-6 lg:mt-8">
          <Button
            className={
              isAgreed
                ? 'p-0 tracking-normal mb-2 h-10 text-[#fff] text-[17px] w-[100px] shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[5px]'
                : 'p-0 tracking-normal mb-2 h-10 text-[#fff] text-[17px] w-[100px] shadow-none border border-login-bt-border cursor-pointer bg-light-gray rounded-[5px]'
            }
            buttonText="Proceed"
            onclick={(e) => {
              console.log('login clicked');
              navigate('/mfa');
            }}
            disabled={!isAgreed}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
