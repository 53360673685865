import React from 'react';
import AreasOfExperties from '../components/AreasOfExperties';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ImageTag from '../library/ImageTag/ImageTag';
import home from '../assets/img/dashboard_banner.png';
import section from '../assets/img/section.png';
import aws from '../assets/img/aws.png';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import { IPPHeading, IPPParagraph, IPPSubHeading } from '../library/Heading/Heading';
import { DashboardText } from '../../src/utils/Helper/enums';

const Dashboard: React.FC<any> = () => {
  return (
    <div className="w-full">
      {/* ParentHeader component is accepting the child component as a prop and rendering it using props.children */}
      <Header>
        <WelcomeHeader />
      </Header>
      <div className="relative bg-opacity-50 lg:grid lg:grid-cols-8 lg:gap-4 w-full ">
        <ImageTag
          src={home}
          className="absolute -z-10 object-cover w-full h-screen lg:h-[39rem] bg-center bg-cover bg-repeat"
          alt="Home"
          height="auto"
          maxWidth="100%"
          name={'Home'}
        />
        <div className="col-start-0 col-span-8 flex flex-col mx-4 break-words  lg:w-1/2 hyphens-auto lg:hyphens-none md:pt-9 lg:pt-0">
          <IPPHeading
            headerText={'Indigo Partner Portal'}
            className={
              'my-6 text-2xl text-white md:text-4xl lg:text-4xl font-bold lg:mt-5 lg:mb-2 lg:my-0'
            }
          />
          <IPPParagraph
            headerText={DashboardText.ThreePartners}
            className={'text-white mb-10 lg:mb-6 my-6'}
          />
          <IPPHeading
            headerText={'We work with Industry'}
            className={
              'my-6 text-3xl text-white md:text-3xl lg:text-3xl font-bold lg:mt-0 lg:mb-4 lg:my-0'
            }
          />
        </div>
      </div>
      <div className="relative md:grid lg:grid ml-2 lg:grid-cols-8 gap-10 ml-2 w-full ">
        <div className="lg:col-start-2 col-span-6  mx-1 lg:mx-0  md:flex  lg:flex flex-row justify-between mt-8 lg:mt-18 lg:mt-[70px]">
          <AreasOfExperties />
        </div>
      </div>
      <div className="relative  lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mb-10 -mt-[25px]">
        <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0  mt-11 lg:mt-20 pb-5 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
          <IPPSubHeading
            headerText={'Access our code and create something new'}
            className={'text-black text-3xl lg:text-4xl font-bold mr-2 px-3.5 lg:mr-0'}
          />
        </div>
      </div>
      <div className="relative  lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mt-2">
        <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0  mt-11 lg:mt-0 pb-5 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
          <IPPParagraph
            headerText={DashboardText.CollaborateWithThreePartners}
            className={'text-black mb-10 lg:mb-10 my-0 px-3.5 -mt-[25px]'}
          />
        </div>
      </div>
      <ImageTag src={section} alt="Section" width="100%" name={'Section'} />
      <div className="relative  lg:grid md:grid ml-2 md:ml-2 lg:ml-2 lg:grid-cols-8 gap-4 w-full mb-10">
        <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0  mt-10 lg:mt-10 pb-5 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
          <IPPSubHeading
            headerText={'We provide the tools to aid collaboration'}
            className={'text-black text-3xl lg:text-4xl font-bold mr-2 lg:mr-0 px-3.5'}
          />
        </div>
      </div>
      <div className="relative  lg:grid md:grid ml-2 md:ml-0 lg:ml-2 lg:grid-cols-8 gap-4 w-full mt-2">
        <div className="col-start-0 col-span-8 flex flex-row lg:justify-between md:justify-between mr-1 lg:mx-0  mt-0 lg:mt-0 pb-5 lg:pb-0 ml-2 md:ml-0 lg:ml-0">
          <IPPParagraph
            headerText={DashboardText.ProvideAccessToIndigoSourceCode}
            className={'text-black mb-20 lg:mb-14 my-0 px-3.5 -mt-[25px]'}
          />
        </div>
      </div>

      <ImageTag
        className="px-3.5  pl-0 md:pl-0 lg:pd-0 -mt-[40px]"
        src={aws}
        alt="Commit"
        width="20%"
        height="30%"
        name={'AWS'}
      />

      <div className="relative bg-gray md:grid  lg:grid lg:grid-cols-8 gap-4 py-4 md:py-8 lg:py-4 border-l-4 border-red ">
        <div className="col-start-4 md:col-start-2 lg:col-start-3 col-span-6  md:flex md:flex-row  lg:flex lg:flex-row justify-center ml-0 lg:ml-24 md:ml-0"></div>
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
