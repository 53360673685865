import { APIResponse } from './type';
import { AxiosResponse } from 'axios';

/**
 * This common function takes in several parameters representing the status of an API response and returns an appropriate APIResponse object.
 * @param status - the HTTP status code returned from the API
 * @param errorMessage - string indicating whether the response is an error message
 * @param successMessage - string indicating whether the response is an success message
 * @returns an APIResponse object with a status code and response body
 */
export const handleApiResponse = (
  status: number,
  errorMessage: string,
  successMessage: string
): APIResponse => {
  switch (status) {
    case 201:
      return {
        statusCode: 201,
        body: successMessage
      };
    case 403:
      return {
        statusCode: 403,
        body: errorMessage
      };
    case 500:
      return {
        statusCode: 500,
        body: errorMessage
      };
    case 404:
      return {
        statusCode: 404,
        body: errorMessage
      };
    case 406:
      return {
        statusCode: 406,
        body: errorMessage
      };
    case 400:
      return {
        statusCode: 400,
        body: errorMessage
      };
    case 401:
      return {
        statusCode: 401,
        body: errorMessage
      };
    case 429:
      return {
        statusCode: 429,
        body: errorMessage
      };
    default:
      return {
        statusCode: status,
        body: errorMessage
      };
  }
};

/**
 * This function takes in an AxiosResponse object and a boolean indicating whether the user is logged in and returns an appropriate APIResponse object.
 * @param response - the AxiosResponse object returned from an API request
 * @param isUserLoggedIn - boolean indicating whether the user is logged in
 * @returns an APIResponse object with a status code and response body
 */
export const handleApiResponseLogin = (
  response: AxiosResponse<any, any>,
  isUserLoggedIn: boolean
): APIResponse => {
  switch (response.status) {
    case 201:
      if (isUserLoggedIn) {
        return {
          statusCode: 201,
          body: 'Profile is validated'
        };
      } else return { statusCode: 201, body: response.data };
    case 403:
      if (isUserLoggedIn) {
        return {
          statusCode: 403,
          body: 'Profile is not validated'
        };
      } else return { statusCode: 403, body: '' };
    case 410:
      if (isUserLoggedIn) {
        return {
          statusCode: 410,
          body: 'OTP mismatched'
        };
      } else return { statusCode: 410, body: '' };
    case 401:
      if (isUserLoggedIn) {
        return {
          statusCode: 401,
          body: 'Too many invalid credentials attempts.'
        };
      } else return { statusCode: 401, body: 'Too many invalid credentials attempts.' };
    default:
      return { statusCode: response.status, body: '' };
  }
};
/**
 * This function takes in several parameters representing the status of an API response and returns an appropriate APIResponse object
 * @param response - the AxiosResponse object returned from an API request
 * @returns an APIResponse object with a status code and response body
 */
export const handleApiResponseConsoleLogin = (response: AxiosResponse<any, any>): APIResponse => {
  switch (response.status) {
    case 201:
      return {
        statusCode: 201,
        body: response.data
      };
    case 400:
      return {
        statusCode: 400,
        body: 'Link is not valid'
      };
    default:
      return { statusCode: response.status, body: 'failed to login' };
  }
};
