import { AxiosResponse } from 'axios';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import { APIResponse } from '../Helper/type';
import IUsers from './UnlockUser.interface';

const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
export default class UnlockUser {
  public apiResponse: APIResponse | undefined;

  // Declaring the constructor to get the request body. This constructor is of type IOrgUser
  constructor(public userInfo: IUsers) {}

  // Declaring the sub-path
  public subPath: string = '/userunlock';

  public async unlockUser(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.subPath, 'POST');
      const url: string = this.createUrl(this.subPath);
      const requestBody: any = this.userInfo;
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody);
      // this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
      this.apiResponse = { statusCode: response.status, body: response?.data };
    } catch (error: any) {
      switch (error?.response?.status) {
        case 406:
          this.apiResponse = {
            statusCode: 406,
            body: 'No data found'
          };
          break;
        default:
          this.apiResponse = { statusCode: 500, body: JSON.stringify(error) };
      }
    }
    return this.apiResponse;
  }

  private createUrl(params?: string): string {
    return backEndServerUrl + params;
  }
}
