// Importing necessary modules
import { AxiosResponse } from 'axios';
import { post } from '../Request/Request';
import { APIResponse } from '../Helper/type';
import Iuser from './User.interface';
import { handleApiResponse } from '../Helper/apiResponseHandler';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';

// Declaring the web server path
const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
// Defining the User class
export default class User {
  public apiResponse: APIResponse | undefined; // A public property to store the API response

  // Declaring the constructor to get the request body. This constructor is of type IUser
  constructor(public userInfo: Iuser) {}

  // Declaring the sub-path
  public createUserPath: string = '/organization/admin';
  public validatePath: string = '/organization/admin/confirmregistration';
  public createBtAdminUserPath: string = '/organization/btadmin';

  // POST request for Registration and storing it to db. This methods returns a response body along with a status code.
  public async createUser(): Promise<APIResponse> {
    try {
      // calling wrapper function to wrap the header.
      const axiosWithRoles = apiCallWrapper(this.createUserPath, 'POST');
      const url: string = this.createUserUrl(this.createUserPath); // Creating the complete URL by combining the server URL and sub-path
      const requestBody: any = this.userInfo; // Getting the user information from the constructor
      const response: AxiosResponse<any, any> = await axiosWithRoles.post(url, requestBody); // Making the POST request using Axios
      const errorMessage = '';
      const successMessage = '';
      // Handling the API response using the handleApiResponse function
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      const errorMessage = '';
      const successMessage = '';
      // Handling the error response using the handleApiResponse function
      this.apiResponse = handleApiResponse(error?.response?.status, errorMessage, successMessage);
    }
    return this.apiResponse;
  }

  // POST request for BT admin Registration and storing it to db. This methods returns a response body along with a status code.
  public async createBtAdminUser(): Promise<APIResponse> {
    try {
      const url: string = this.createBtAdminUserUrl(this.createBtAdminUserPath); // Creating the complete URL by combining the server URL and sub-path
      const requestBody: any = this.userInfo; // Getting the user information from the constructor
      const response: AxiosResponse<any, any> = await post(requestBody, url); // Making the POST request using Axios
      const errorMessage = '';
      const successMessage = '';
      // Handling the error response using the handleApiResponse function
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      const errorMessage = '';
      const successMessage = '';
      // Handling the error response using the handleApiResponse function
      this.apiResponse = handleApiResponse(error?.response?.status, errorMessage, successMessage);
    }
    return this.apiResponse;
  }

  // POST request for Confirm Registration and storing it to db. This methods returns a response body along with a status code.
  public async doValidate(): Promise<APIResponse> {
    try {
      const url: string = this.validateUrl(this.validatePath); // Creating the complete URL by combining the server URL and sub-path
      const requestBody: any = this.userInfo; // Getting the user information from the constructor
      const response: AxiosResponse<any, any> = await post(requestBody, url); // Making the POST request using Axios
      const errorMessage = '';
      const successMessage = '';
      // Handling the error response using the handleApiResponse function
      this.apiResponse = handleApiResponse(response.status, errorMessage, successMessage);
    } catch (error: any) {
      this.apiResponse = {
        statusCode: 500,
        body: JSON.stringify(error)
      };
    }
    return this.apiResponse;
  }
  //   Paths for different API

  private createUserUrl(params?: string): string {
    return backEndServerUrl + params;
  }

  // Binding the Webserver path and sub-path for BT admin registration
  private createBtAdminUserUrl(params?: string): string {
    return backEndServerUrl + params;
  }

  // Binding the Webserver path and sub-path for Confirm registration
  private validateUrl(paramsValidate?: string): string {
    return backEndServerUrl + paramsValidate;
  }
}
