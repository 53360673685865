export enum UserType {
  BtAdmin = 0,
  OrganizationAdmin = 1,
  OrganizationUser = 2
}

export enum ModuleAccessLevel { // for more info on the access levels see:  https://wiki.tvac.bt.co.uk/x/gIiKD
  NOACCESS = 'No Access',
  FULLACCESS = 'Full Access',
  PARTIALACCESS = 'Partial Access'
}

export enum BroadCastChannel {
  BROADCAST_CHANNEL_NAME = 'logoutChannel'
}

export enum InitialFlag {
  Admin = 'admin',
  User = 'user',
  BtAdmin = 'btAdmin'
}
export enum JobTitle {
  Developer = 'Developer',
  Admin = 'Admin'
}

export enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Ms = 'Ms',
  Dr = 'Dr',
  Prof = 'Professor',
  Sir = 'Sir'
}

export enum responseMessages {
  CreateUserSuccess = 'User Created Successfully',
  UpdateUserSuccess = 'User Updated Successfully',
  UserExists = 'User already exists in the Database',
  FeedbackSubmitted = 'Your feedback is submitted successfully',
  SomethingWentWrong = 'Something went wrong'
}

export enum DashboardText {
  ThreePartners = 'Our three great brands - BT, EE and Plusnet enable millions of customers to use the internet. Our best-in-class routers and Wi-Fi extenders enable devices to connect seamlessly and safely. Intelligent software empower our customers to manage and optimize their service. Indigo is our software platform powering this experience.',
  CollaborateWithThreePartners = 'We collaborate with the best partners in industry to create innovative new products and services for our customers. Join the Indigo Partner Portal and access to the indigo source code. Build something new as you combine your product with Indigo. This can happen within the context of an exiting business relationship or if you want to start something new with us. Our door is open.',
  ProvideAccessToIndigoSourceCode = 'We provide access to the Indigo source code via AWS CodeCommit with the Partner Portal providing all the tools you need to manage users and access to code modules. AWS CodeCommit is build on git which is perfect for multi-organisation collaboration.'
}

export enum CardContent {
  SiliconProviders = 'At the core of all CPE hardware are the chipsets e.g. SoC, Wi-fi, Dect, Iot. The strategy for Indigo is to support a diversity of chipsets to allow us maximum choice when building new products.',
  DeviceManufacturers = 'We work with ODM partners to build these for us. The ODMs in turn work with the silicon manufacturers. Together they provide the platform of hardware and low-level software that Indigo runs on.',
  SpecialistSoftwareProviders = 'When appropriate Indigo may make use of specialist software modules to provide the features required. Examples include software DSP for voice, MPTCP, speed test.',
  ThirdPartyAppSuppliers = 'Indigo includes a container framework to allow sandboxed applications to excute on the CPE. These can interact with the device via a high-level API based on USP.'
}
