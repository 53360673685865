import React from 'react';

interface ILabel {
  className: string;
  LabelText?: string;
  disabled?: boolean;
  onClick?: (e: React.FormEvent) => void;
  htmlFor?: string;
  children?: React.ReactNode;
}

const Label: React.FC<ILabel> = ({ className, LabelText, onClick, htmlFor, children }) => {
  return (
    <label className={className} onClick={onClick} htmlFor={htmlFor}>
      {LabelText}
      {children}
    </label>
  );
};

export default Label;
