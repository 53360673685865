import React, { Fragment, useCallback, useEffect, useState } from 'react';
import UserRow from '../UserRow';
import Search from '../Search';
import IUserList from '../../utils/UserList/UserList.interface';
import ReactLoading from 'react-loading';
import { CreateUserForm } from './CreateUser';
import { IPPHeading, IPPParagraph } from '../../library/Heading/Heading';

/**
 * This common props takes in several parameters.
 * @data - These prop is likely used to store the modules list received from back-end.
 * @isLoading - This prop is  used to implement the loader while data is being fetched from back-end.
 * @query - These prop is used to implement search functionality based on user's input
 * @setQuery - These prop is used to update the @query based on user's input
 * @numValuesToShow - This prop is used ti implement show functionality based on selected number of data.
 * @handleNumValuesChange - This prop is used to update the @numValuesToShow base on user's input.
 * @createUser - Dummy function to create user.
 * @valuesToShoe - Store the @numValuesToShow data.
 **/
interface UserListProp {
  data: any[];
  isLoading: boolean;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  numValuesToShow: string;
  handleNumValuesChange: any;
  valuesToShow: any;
  name: string;
  email: string;
  onCompanyEmailChange: (email: string) => void;
  onCompanyNameChange: (name: string) => void;
  partnerName: string;
  tableName: string;
  onDeleteUser: () => void;
  onUserCreated?: () => void;
}
// React functional component that displays a list of Admin users in a table format with a search bar and a dropdown to change the number of users to display.
const UserList: React.FC<UserListProp> = ({
  data,
  isLoading,
  query,
  setQuery,
  numValuesToShow,
  handleNumValuesChange,
  valuesToShow,
  name,
  email,
  onCompanyEmailChange,
  onCompanyNameChange,
  partnerName,
  tableName,
  onDeleteUser,
  onUserCreated
}) => {
  const orgEmail = sessionStorage.getItem('userEmail');
  const partnerCompanyName = sessionStorage.getItem('companyName');
  const partnerDomainList = sessionStorage.getItem('domainList');
  const orgDomain = partnerDomainList != null ? JSON.parse(partnerDomainList) : [];
  sessionStorage.setItem('UserList Length', valuesToShow.length);
  const emailToDelete = sessionStorage.getItem('Email');

  const indexToDelete = valuesToShow.findIndex(
    (item: { email: string | null }) => item.email === emailToDelete
  );

  if (indexToDelete !== -1) {
    valuesToShow.splice(indexToDelete, 1);
  }

  const getUserName = (userEmail: string) => {
    const updatedName = sessionStorage.getItem(`Update Name ${userEmail}`);
    return updatedName !== null
      ? updatedName
      : valuesToShow.find((user: { email: string }) => user.email === userEmail).name;
  };

  const getUserRole = (userEmail: string) => {
    const updatedRole = sessionStorage.getItem(`Updated Role ${userEmail}`);
    return updatedRole !== null
      ? updatedRole
      : valuesToShow.find((user: { email: string }) => user.email === userEmail).role;
  };

  return (
    <Fragment>
      <div style={{ width: '100%' }}>
        <div className="bg-white block" style={{ paddingInline: '2px 2px', width: '100%' }}>
          <div className="flex justify-between">
            <IPPHeading
              headerText={'Organisation Users'}
              className={'text-black text-xl leading-tight font-medium mb-0'}
            />
            <CreateUserForm
              organisationEmail={orgEmail}
              organisationName={partnerCompanyName}
              organisationDomain={orgDomain}
              callback={onUserCreated}
            />
          </div>
          <br />
          <hr />
          <br />
          <Search onChange={(e: any) => setQuery(e.target.value)} text="Search by name..." />
          {/* Todo - fix with next release */}
          {/* <Show value={numValuesToShow} onChange={handleNumValuesChange} /> */}
          <br />
          <br />
          {isLoading ? (
            // If the data is still being fetched, display a loading animation
            <ReactLoading
              className="flex justify-center"
              type="bubbles"
              color="#000000"
              height={100}
              width={900}
            />
          ) : (
            <div className="w-full h-[300px] overflow-y-auto">
              {/* If data is available, display the table */}
              <table className="mx-auto max-w-4xl w-full table-fixed whitespace-nowrap rounded-lg bg-white divide-y divide-gray-300">
                <thead className="bg-[#f8f8f8]">
                  <tr className="text-black text-center">
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Display Name{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Email{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Role{' '}
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      {' '}
                      Action{' '}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <tr className="text-light-gray">
                    <td className="px-6 py-4 text-center">
                      {' '}
                      <IPPParagraph headerText={`${partnerName}`} className={''} />
                    </td>
                    {email === null ? (
                      <td className="px-6 py-4 text-center hidden">
                        {' '}
                        <IPPParagraph headerText={`${orgEmail}`} className={''} />
                      </td>
                    ) : (
                      <td className="px-6 py-4 text-center ">
                        {' '}
                        <IPPParagraph headerText={`${orgEmail}`} className={''} />
                      </td>
                    )}
                    <td className="px-6 py-4 text-center ">
                      {' '}
                      <IPPParagraph headerText={'Admin'} className={''} />
                    </td>
                  </tr>
                  {/* data is mapped into db and the values are stored of type IUserList. Here the index is of type number which generally determines the key value */}
                  {valuesToShow
                    .sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
                    .filter((user: { name: string }) =>
                      user.name.toLowerCase().includes(query.toLowerCase())
                    )
                    .map((dataSet: IUserList, index: number) => (
                      <UserRow
                        tableName={tableName}
                        key={index}
                        name={getUserName(dataSet.email!)}
                        email={dataSet.email!}
                        role={getUserRole(dataSet.email!)}
                        phone={dataSet.phone}
                        level={dataSet.level!}
                        progress={dataSet.progress!}
                        icon={dataSet.icon}
                        module={dataSet.module!}
                        buttonStatus={dataSet.buttonStatus!}
                        status={dataSet.status!}
                        companyName={dataSet.companyName!}
                        orgEmail={dataSet.orgEmail!}
                        onCompanyEmailChange={onCompanyEmailChange}
                        onCompanyNameChange={onCompanyNameChange}
                        disabled={false}
                        onDeleteUser={onDeleteUser}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default UserList;
