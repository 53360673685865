import React from 'react';
import './App.css';

import { Routes, Route } from 'react-router-dom';
import { ProtectedRoutes } from './components/ProtectedRoute';
import LoginContextProvider from './context/LoginContextprovider';

import ConfirmRegistration from './pages/ConfirmRegistration';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import AdminPanel from './pages/AdminPanel';
import BtAdminPanel from './pages/BtAdminPanel';
import UserPanel from './pages/UserPanel';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPassword from './pages/ForgotPassword';
import PasswordSetup from './pages/PasswordSetup';
import Mfa from './pages/Mfa';
import TermsAndConditions from './pages/TermsAndConditions';
import { CompanyNameProvider } from './context/CompanyNameContext';

function App(): any {
  return (
    <LoginContextProvider>
      <CompanyNameProvider>
        <Routes>
          <Route path="/" index={true} element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/mfa" element={<Mfa />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/btadmin" element={<BtAdminPanel />} />
            <Route path="/user" element={<UserPanel />} />
            <Route path="/register/*" element={<Register />} />
          </Route>
          <Route path="/otp" element={<ConfirmRegistration />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/reset/otp" element={<ForgotPassword />}></Route>
          <Route path="/setup/password" element={<PasswordSetup />}></Route>
        </Routes>
      </CompanyNameProvider>
    </LoginContextProvider>
  );
}

export default App;
