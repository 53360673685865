import React, { Dispatch, Suspense, useState } from 'react';
import InputField from '../../../library/inputField/inputField';
import ReactLoading from 'react-loading';
import { IPPHeading } from '../../../library/Heading/Heading';
import { ModuleAccessLevel } from '../../../utils/Helper/enums';
import IModuleList from '../../../utils/ModuleList/ModuleList.interface';
import { IModuleAceess, IPreset } from '../../../utils/ModulePresets/preset.interface';

interface Props {
  name: string;
  baseModules: [];
  systemModules: [];
  submitModule?: (preset: IPreset | {}) => void;
  disabled?: boolean;
  initialSelectedAccess?: IModuleAceess[];
  isLoading?: boolean;
  isSubmitting?: boolean;
  showSystemModules?: boolean;
  selectedAccessTypes: { [moduleName: string]: string };
  defaultAccessTypes?: { [moduleName: string]: string };
  setSelectedAccessTypes: Dispatch<React.SetStateAction<{ [moduleName: string]: string }>>;
}
// To Display access module list with the selected access types
// to show custom module tab pass showSystemModules prop as true with systenModule list
const ModuleAccessList = ({
  baseModules,
  systemModules,
  selectedAccessTypes,
  setSelectedAccessTypes,
  defaultAccessTypes,
  showSystemModules,
  disabled
}: Props) => {
  const [activeTab, setActiveTab] = useState('Base Modules');

  const handleRadioButtonChange = (moduleName: string, accessType: ModuleAccessLevel) => {
    if (disabled) {
      return;
    }
    setSelectedAccessTypes((prvs) => {
      const newObj = {
        ...prvs,
        [moduleName]: accessType
      };
      return newObj;
    });
  };

  const switchTab = (tab: string) => {
    setActiveTab(tab);
  };
  const isPartialAccessSelected = (moduleName: string) => {
    return selectedAccessTypes[moduleName] === ModuleAccessLevel.PARTIALACCESS;
  };
  const isFullAccessSelected = (moduleName: string) => {
    return selectedAccessTypes[moduleName] === ModuleAccessLevel.FULLACCESS;
  };

  return (
    <Suspense
      fallback={
        <ReactLoading
          className="flex justify-center my-[4rem]"
          type="bubbles"
          color="#000000"
          height={100}
          width={350}
        />
      }
    >
      <div className="flex justify-center border-b border-black">
        <div
          className={
            activeTab === 'Base Modules'
              ? 'inline-block bg-gray text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center active cursor-pointer'
              : 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center cursor-pointer'
          }
          onClick={() => switchTab('Base Modules')}
        >
          Base Modules
        </div>
        {showSystemModules && (
          <div
            className={
              activeTab === 'Custom Modules'
                ? 'inline-block bg-gray text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center active cursor-pointer'
                : 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center cursor-pointer'
            }
            onClick={() => switchTab('Custom Modules')}
          >
            Custom Modules
          </div>
        )}
      </div>
      <div>
        {activeTab === 'Base Modules' &&
          (baseModules.length > 0 ? (
            <div className="position-relative grow shrink basis-auto p-[1rem]block max-h-[30rem] overflow-y-auto">
              <table className="w-100% border-collapse">
                <thead className="table-header-group align-middle bg-[#f8f8f8] sticky-header">
                  <tr className="text-black text-left table-row align-inherit">
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      Module Name
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      Full Access
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      Partial Access
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      No Access
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {baseModules
                    .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                      a.moduleName.localeCompare(b.moduleName)
                    )
                    .map((module: IModuleList, index: number) => (
                      <tr key={index}>
                        <div className="mt-[5px]">
                          <td
                            className={`px-10 py-4 text-center ${
                              (selectedAccessTypes[module.moduleName!] !==
                                ModuleAccessLevel.NOACCESS ||
                                (defaultAccessTypes &&
                                  defaultAccessTypes[module.moduleName!] !== undefined)) &&
                              defaultAccessTypes &&
                              selectedAccessTypes[module.moduleName!] !==
                                defaultAccessTypes[module.moduleName!]
                                ? 'bg-gray rounded-[50px]'
                                : ''
                            }`}
                          >
                            {module.moduleName}
                          </td>
                        </div>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center ml-[2.2rem]">
                            <InputField
                              id={`full-access-radio-${index}`}
                              type="radio"
                              name={module.moduleName!}
                              className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black ${
                                disabled ? 'cursor-not-allowed' : ''
                              }`}
                              checked={isFullAccessSelected(module.moduleName!)}
                              onChange={() =>
                                handleRadioButtonChange(
                                  module.moduleName!,
                                  ModuleAccessLevel.FULLACCESS
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center ml-[3rem]">
                            <InputField
                              id={`partial-access-radio-${index}`}
                              type="radio"
                              name={module.moduleName!}
                              className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black ${
                                disabled ? 'cursor-not-allowed' : ''
                              }`}
                              checked={isPartialAccessSelected(module.moduleName!)}
                              onChange={() =>
                                handleRadioButtonChange(
                                  module.moduleName!,
                                  ModuleAccessLevel.PARTIALACCESS
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center ml-[2rem]">
                            <InputField
                              id={`no-access-radio-${index}`}
                              type="radio"
                              name={module.moduleName!}
                              className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black ${
                                disabled ? 'cursor-not-allowed' : ''
                              }`}
                              checked={
                                selectedAccessTypes[module.moduleName!] ===
                                  ModuleAccessLevel.NOACCESS ||
                                (!isPartialAccessSelected(module.moduleName!) &&
                                  !isFullAccessSelected(module.moduleName!))
                              }
                              onChange={() =>
                                handleRadioButtonChange(
                                  module.moduleName!,
                                  ModuleAccessLevel.NOACCESS
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            baseModules.length === 0 && (
              <div
                className="px-6 py-4 text-center"
                style={{ paddingTop: '5rem', fontWeight: 'bold' }}
              >
                <IPPHeading headerText={'No data found ☹️ !!'} className={'text-bold'} />
              </div>
            )
          ))}
        {activeTab === 'Custom Modules' &&
          (systemModules.length > 0 ? (
            <div className="position-relative grow shrink basis-auto p-[1rem]block max-h-[30rem] overflow-y-auto">
              <table className="w-100% border-collapse">
                <thead className="table-header-group align-middle bg-[#f8f8f8] sticky-header">
                  <tr className="text-black text-left table-row align-inherit">
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      Module Name
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      Full Access
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      Partial Access
                    </th>
                    <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                      No Access
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {systemModules
                    .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                      a.moduleName.localeCompare(b.moduleName)
                    )
                    .map((module: IModuleList, index: number) => (
                      <tr key={index}>
                        <div className="mt-[5px]">
                          <td
                            className={`px-10 py-4 text-center ${
                              (selectedAccessTypes[module.moduleName!] !==
                                ModuleAccessLevel.NOACCESS ||
                                (defaultAccessTypes &&
                                  defaultAccessTypes[module.moduleName!] !== undefined)) &&
                              defaultAccessTypes &&
                              selectedAccessTypes[module.moduleName!] !==
                                defaultAccessTypes[module.moduleName!]
                                ? 'bg-gray rounded-[50px]'
                                : ''
                            }`}
                          >
                            {module.moduleName}
                          </td>
                        </div>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center ml-[2.2rem]">
                            <InputField
                              id={`full-access-radio-${index}`}
                              type="radio"
                              name={module.moduleName!}
                              className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black ${
                                disabled ? 'cursor-not-allowed' : ''
                              }`}
                              checked={isFullAccessSelected(module.moduleName!)}
                              onChange={() =>
                                handleRadioButtonChange(
                                  module.moduleName!,
                                  ModuleAccessLevel.FULLACCESS
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center ml-[3rem]">
                            <InputField
                              id={`partial-access-radio-${index}`}
                              type="radio"
                              name={module.moduleName!}
                              className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black ${
                                disabled ? 'cursor-not-allowed' : ''
                              }`}
                              checked={isPartialAccessSelected(module.moduleName!)}
                              onChange={() =>
                                handleRadioButtonChange(
                                  module.moduleName!,
                                  ModuleAccessLevel.PARTIALACCESS
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 text-center">
                          <div className="flex items-center ml-[2rem]">
                            <InputField
                              id={`no-access-radio-${index}`}
                              type="radio"
                              name={module.moduleName!}
                              className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black ${
                                disabled ? 'cursor-not-allowed' : ''
                              }`}
                              checked={
                                selectedAccessTypes[module.moduleName!] ===
                                  ModuleAccessLevel.NOACCESS ||
                                (!isPartialAccessSelected(module.moduleName!) &&
                                  !isFullAccessSelected(module.moduleName!))
                              }
                              onChange={() =>
                                handleRadioButtonChange(
                                  module.moduleName!,
                                  ModuleAccessLevel.NOACCESS
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            systemModules.length === 0 && (
              <div
                className="px-6 py-4 text-center"
                style={{ paddingTop: '5rem', fontWeight: 'bold' }}
              >
                <IPPHeading headerText={'No data found ☹️ !!'} className={'text-bold'} />
              </div>
            )
          ))}
      </div>
    </Suspense>
  );
};

export default ModuleAccessList;
