import React, { useEffect } from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';

/**
 * This common props takes in several parameters.
 * @onPageChange - Callback function to handle page changes.
 * @totalCount - Total number of items to be paginated.
 * @siblingCount - Number of additional page numbers to display on each side of the current page.
 * @currentPage - The current active page.
 * @pageSize - Number of items to display per page.
 * @className - Additional CSS class for styling customization.
 **/

interface PaginationProp {
  onPageChange: (newPage: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: string;
}

const Pagination: React.FC<PaginationProp> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className
}) => {
  useEffect(() => {
    sessionStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    const newPage = currentPage + 1;
    onPageChange(newPage);
  };

  const onPrevious = () => {
    const newPage = Math.max(currentPage - 1, 1);
    onPageChange(newPage);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={classnames('pagination-container', { [className]: className })}>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber: any, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li key={`dots-${index}`} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
