import React from 'react';
interface IInputField {
  id: string;
  type: string;
  className: string;
  value?: string | number | readonly string[] | undefined;
  reference?: any;
  required?: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  minimum?: any;
  onClick?: (e: React.FormEvent) => void;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: any;
  name?: string;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => Promise<void>;
  checked?: boolean;
  readOnly?: true;
  register?: any;
}

const InputField: React.FC<IInputField> = ({
  id,
  type,
  className,
  value,
  reference,
  required,
  style,
  disabled,
  maxLength,
  onChange,
  onClick,
  minimum = () => {},
  placeholder,
  onFocus,
  onBlur,
  name,
  onKeyUp,
  checked,
  readOnly,
  register
}) => {
  return (
    <input
      maxLength={maxLength ?? 40}
      id={id}
      type={type}
      className={className}
      style={style}
      required={required}
      disabled={disabled}
      ref={reference}
      value={value}
      onChange={onChange}
      min={minimum()}
      onClick={onClick}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
      name={name}
      onKeyUp={onKeyUp}
      checked={checked}
      readOnly={readOnly}
      {...register}
    />
  );
};
export default InputField;
